var qs = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
};
function Bs(e) {
  return e;
}
function J(e, t, r, n, s, i, o, c, u) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return s(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return i(s(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(i(s(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(i(s(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return u(c(o(i(s(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function y(e, t, r, n, s, i, o, c, u) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return s(n(r(t(e))));
    case 6:
      return i(s(n(r(t(e)))));
    case 7:
      return o(i(s(n(r(t(e))))));
    case 8:
      return c(o(i(s(n(r(t(e)))))));
    case 9:
      return u(c(o(i(s(n(r(t(e))))))));
    default: {
      for (var l = arguments[0], f = 1; f < arguments.length; f++)
        l = arguments[f](l);
      return l;
    }
  }
}
var Gs = function(e, t) {
  var r = function(n) {
    return n.length >= e;
  };
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(s) {
      return t.apply(void 0, qs([s], n, !1));
    };
  };
}, zs = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, Fs = function(e) {
  return zs(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
}, Wn = function(e) {
  return e._tag === "Some";
}, Qn = { _tag: "None" }, Jn = function(e) {
  return { _tag: "Some", value: e };
}, Ks = function(e) {
  return e._tag === "Left";
}, Ws = function(e) {
  return e._tag === "Right";
}, Qs = function(e) {
  return { _tag: "Left", left: e };
}, Js = function(e) {
  return { _tag: "Right", right: e };
}, Ys = function(e) {
  return [e];
}, Xs = [], ei = Object.prototype.hasOwnProperty, ti = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, s) {
        return e.concat(n, s);
      }, t);
    };
  };
}, ri = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var s in e)
        ei.call(e, s) && (n[s] = e[s].concat(t[s], r[s]));
      return n;
    }
  };
}, rr = function() {
  return { concat: Bs };
}, ni = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, ai = ti, en = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, si = Xs, ii = function(e, t) {
  return e < 0 || e >= t.length;
}, oi = function(e) {
  return function(t) {
    return en(en([], t, !0), [e], !1);
  };
}, ci = oi, ui = Ys, W = {
  equals: function(e, t) {
    return e === t;
  }
}, li = {
  concat: function(e, t) {
    return e + t;
  }
}, di = "", Tr = {
  concat: li.concat,
  empty: di
};
W.equals;
var Yn = function(e) {
  return e.trim();
}, Xn = function(e) {
  return e.length === 0;
}, vt = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function ea(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function fi() {
  this.__data__ = [], this.size = 0;
}
var hi = fi;
function pi(e, t) {
  return e === t || e !== e && t !== t;
}
var $r = pi, vi = $r;
function mi(e, t) {
  for (var r = e.length; r--; )
    if (vi(e[r][0], t))
      return r;
  return -1;
}
var It = mi, gi = It, yi = Array.prototype, _i = yi.splice;
function bi(e) {
  var t = this.__data__, r = gi(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : _i.call(t, r, 1), --this.size, !0;
}
var Ti = bi, $i = It;
function Ai(e) {
  var t = this.__data__, r = $i(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var Si = Ai, Ei = It;
function wi(e) {
  return Ei(this.__data__, e) > -1;
}
var xi = wi, ki = It;
function ji(e, t) {
  var r = this.__data__, n = ki(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Ri = ji, Oi = hi, Ci = Ti, Ii = Si, Mi = xi, Ni = Ri;
function Se(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Se.prototype.clear = Oi;
Se.prototype.delete = Ci;
Se.prototype.get = Ii;
Se.prototype.has = Mi;
Se.prototype.set = Ni;
var Mt = Se, Pi = Mt;
function Vi() {
  this.__data__ = new Pi(), this.size = 0;
}
var Di = Vi;
function Li(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var Ui = Li;
function Zi(e) {
  return this.__data__.get(e);
}
var Hi = Zi;
function qi(e) {
  return this.__data__.has(e);
}
var Bi = qi, Gi = typeof vt == "object" && vt && vt.Object === Object && vt, ta = Gi, zi = ta, Fi = typeof self == "object" && self && self.Object === Object && self, Ki = zi || Fi || Function("return this")(), z = Ki, Wi = z, Qi = Wi.Symbol, Nt = Qi, tn = Nt, ra = Object.prototype, Ji = ra.hasOwnProperty, Yi = ra.toString, De = tn ? tn.toStringTag : void 0;
function Xi(e) {
  var t = Ji.call(e, De), r = e[De];
  try {
    e[De] = void 0;
    var n = !0;
  } catch {
  }
  var s = Yi.call(e);
  return n && (t ? e[De] = r : delete e[De]), s;
}
var eo = Xi, to = Object.prototype, ro = to.toString;
function no(e) {
  return ro.call(e);
}
var ao = no, rn = Nt, so = eo, io = ao, oo = "[object Null]", co = "[object Undefined]", nn = rn ? rn.toStringTag : void 0;
function uo(e) {
  return e == null ? e === void 0 ? co : oo : nn && nn in Object(e) ? so(e) : io(e);
}
var Pt = uo;
function lo(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var rt = lo, fo = Pt, ho = rt, po = "[object AsyncFunction]", vo = "[object Function]", mo = "[object GeneratorFunction]", go = "[object Proxy]";
function yo(e) {
  if (!ho(e))
    return !1;
  var t = fo(e);
  return t == vo || t == mo || t == po || t == go;
}
var na = yo, _o = z, bo = _o["__core-js_shared__"], To = bo, nr = To, an = function() {
  var e = /[^.]+$/.exec(nr && nr.keys && nr.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function $o(e) {
  return !!an && an in e;
}
var Ao = $o, So = Function.prototype, Eo = So.toString;
function wo(e) {
  if (e != null) {
    try {
      return Eo.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var aa = wo, xo = na, ko = Ao, jo = rt, Ro = aa, Oo = /[\\^$.*+?()[\]{}|]/g, Co = /^\[object .+?Constructor\]$/, Io = Function.prototype, Mo = Object.prototype, No = Io.toString, Po = Mo.hasOwnProperty, Vo = RegExp(
  "^" + No.call(Po).replace(Oo, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Do(e) {
  if (!jo(e) || ko(e))
    return !1;
  var t = xo(e) ? Vo : Co;
  return t.test(Ro(e));
}
var Lo = Do;
function Uo(e, t) {
  return e == null ? void 0 : e[t];
}
var Zo = Uo, Ho = Lo, qo = Zo;
function Bo(e, t) {
  var r = qo(e, t);
  return Ho(r) ? r : void 0;
}
var le = Bo, Go = le, zo = z, Fo = Go(zo, "Map"), Ar = Fo, Ko = le, Wo = Ko(Object, "create"), Vt = Wo, sn = Vt;
function Qo() {
  this.__data__ = sn ? sn(null) : {}, this.size = 0;
}
var Jo = Qo;
function Yo(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Xo = Yo, ec = Vt, tc = "__lodash_hash_undefined__", rc = Object.prototype, nc = rc.hasOwnProperty;
function ac(e) {
  var t = this.__data__;
  if (ec) {
    var r = t[e];
    return r === tc ? void 0 : r;
  }
  return nc.call(t, e) ? t[e] : void 0;
}
var sc = ac, ic = Vt, oc = Object.prototype, cc = oc.hasOwnProperty;
function uc(e) {
  var t = this.__data__;
  return ic ? t[e] !== void 0 : cc.call(t, e);
}
var lc = uc, dc = Vt, fc = "__lodash_hash_undefined__";
function hc(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = dc && t === void 0 ? fc : t, this;
}
var pc = hc, vc = Jo, mc = Xo, gc = sc, yc = lc, _c = pc;
function Ee(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Ee.prototype.clear = vc;
Ee.prototype.delete = mc;
Ee.prototype.get = gc;
Ee.prototype.has = yc;
Ee.prototype.set = _c;
var bc = Ee, on = bc, Tc = Mt, $c = Ar;
function Ac() {
  this.size = 0, this.__data__ = {
    hash: new on(),
    map: new ($c || Tc)(),
    string: new on()
  };
}
var Sc = Ac;
function Ec(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var wc = Ec, xc = wc;
function kc(e, t) {
  var r = e.__data__;
  return xc(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var Dt = kc, jc = Dt;
function Rc(e) {
  var t = jc(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Oc = Rc, Cc = Dt;
function Ic(e) {
  return Cc(this, e).get(e);
}
var Mc = Ic, Nc = Dt;
function Pc(e) {
  return Nc(this, e).has(e);
}
var Vc = Pc, Dc = Dt;
function Lc(e, t) {
  var r = Dc(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var Uc = Lc, Zc = Sc, Hc = Oc, qc = Mc, Bc = Vc, Gc = Uc;
function we(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
we.prototype.clear = Zc;
we.prototype.delete = Hc;
we.prototype.get = qc;
we.prototype.has = Bc;
we.prototype.set = Gc;
var sa = we, zc = Mt, Fc = Ar, Kc = sa, Wc = 200;
function Qc(e, t) {
  var r = this.__data__;
  if (r instanceof zc) {
    var n = r.__data__;
    if (!Fc || n.length < Wc - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new Kc(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var Jc = Qc, Yc = Mt, Xc = Di, eu = Ui, tu = Hi, ru = Bi, nu = Jc;
function xe(e) {
  var t = this.__data__ = new Yc(e);
  this.size = t.size;
}
xe.prototype.clear = Xc;
xe.prototype.delete = eu;
xe.prototype.get = tu;
xe.prototype.has = ru;
xe.prototype.set = nu;
var ia = xe;
function au(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var su = au, iu = le, ou = function() {
  try {
    var e = iu(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), cu = ou, cn = cu;
function uu(e, t, r) {
  t == "__proto__" && cn ? cn(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var oa = uu, lu = oa, du = $r, fu = Object.prototype, hu = fu.hasOwnProperty;
function pu(e, t, r) {
  var n = e[t];
  (!(hu.call(e, t) && du(n, r)) || r === void 0 && !(t in e)) && lu(e, t, r);
}
var ca = pu, vu = ca, mu = oa;
function gu(e, t, r, n) {
  var s = !r;
  r || (r = {});
  for (var i = -1, o = t.length; ++i < o; ) {
    var c = t[i], u = n ? n(r[c], e[c], c, r, e) : void 0;
    u === void 0 && (u = e[c]), s ? mu(r, c, u) : vu(r, c, u);
  }
  return r;
}
var Lt = gu;
function yu(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var _u = yu;
function bu(e) {
  return e != null && typeof e == "object";
}
var ke = bu, Tu = Pt, $u = ke, Au = "[object Arguments]";
function Su(e) {
  return $u(e) && Tu(e) == Au;
}
var Eu = Su, un = Eu, wu = ke, ua = Object.prototype, xu = ua.hasOwnProperty, ku = ua.propertyIsEnumerable, ju = un(/* @__PURE__ */ function() {
  return arguments;
}()) ? un : function(e) {
  return wu(e) && xu.call(e, "callee") && !ku.call(e, "callee");
}, Ru = ju, Ou = Array.isArray, Ut = Ou, yt = { exports: {} };
function Cu() {
  return !1;
}
var Iu = Cu;
yt.exports;
(function(e, t) {
  var r = z, n = Iu, s = t && !t.nodeType && t, i = s && !0 && e && !e.nodeType && e, o = i && i.exports === s, c = o ? r.Buffer : void 0, u = c ? c.isBuffer : void 0, l = u || n;
  e.exports = l;
})(yt, yt.exports);
var Sr = yt.exports, Mu = 9007199254740991, Nu = /^(?:0|[1-9]\d*)$/;
function Pu(e, t) {
  var r = typeof e;
  return t = t ?? Mu, !!t && (r == "number" || r != "symbol" && Nu.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Vu = Pu, Du = 9007199254740991;
function Lu(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Du;
}
var la = Lu, Uu = Pt, Zu = la, Hu = ke, qu = "[object Arguments]", Bu = "[object Array]", Gu = "[object Boolean]", zu = "[object Date]", Fu = "[object Error]", Ku = "[object Function]", Wu = "[object Map]", Qu = "[object Number]", Ju = "[object Object]", Yu = "[object RegExp]", Xu = "[object Set]", el = "[object String]", tl = "[object WeakMap]", rl = "[object ArrayBuffer]", nl = "[object DataView]", al = "[object Float32Array]", sl = "[object Float64Array]", il = "[object Int8Array]", ol = "[object Int16Array]", cl = "[object Int32Array]", ul = "[object Uint8Array]", ll = "[object Uint8ClampedArray]", dl = "[object Uint16Array]", fl = "[object Uint32Array]", E = {};
E[al] = E[sl] = E[il] = E[ol] = E[cl] = E[ul] = E[ll] = E[dl] = E[fl] = !0;
E[qu] = E[Bu] = E[rl] = E[Gu] = E[nl] = E[zu] = E[Fu] = E[Ku] = E[Wu] = E[Qu] = E[Ju] = E[Yu] = E[Xu] = E[el] = E[tl] = !1;
function hl(e) {
  return Hu(e) && Zu(e.length) && !!E[Uu(e)];
}
var pl = hl;
function vl(e) {
  return function(t) {
    return e(t);
  };
}
var Er = vl, _t = { exports: {} };
_t.exports;
(function(e, t) {
  var r = ta, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i && r.process, c = function() {
    try {
      var u = s && s.require && s.require("util").types;
      return u || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(_t, _t.exports);
var wr = _t.exports, ml = pl, gl = Er, ln = wr, dn = ln && ln.isTypedArray, yl = dn ? gl(dn) : ml, da = yl, _l = _u, bl = Ru, Tl = Ut, $l = Sr, Al = Vu, Sl = da, El = Object.prototype, wl = El.hasOwnProperty;
function xl(e, t) {
  var r = Tl(e), n = !r && bl(e), s = !r && !n && $l(e), i = !r && !n && !s && Sl(e), o = r || n || s || i, c = o ? _l(e.length, String) : [], u = c.length;
  for (var l in e)
    (t || wl.call(e, l)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    s && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    i && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    Al(l, u))) && c.push(l);
  return c;
}
var fa = xl, kl = Object.prototype;
function jl(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || kl;
  return e === r;
}
var xr = jl;
function Rl(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var ha = Rl, Ol = ha, Cl = Ol(Object.keys, Object), Il = Cl, Ml = xr, Nl = Il, Pl = Object.prototype, Vl = Pl.hasOwnProperty;
function Dl(e) {
  if (!Ml(e))
    return Nl(e);
  var t = [];
  for (var r in Object(e))
    Vl.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var Ll = Dl, Ul = na, Zl = la;
function Hl(e) {
  return e != null && Zl(e.length) && !Ul(e);
}
var pa = Hl, ql = fa, Bl = Ll, Gl = pa;
function zl(e) {
  return Gl(e) ? ql(e) : Bl(e);
}
var kr = zl, Fl = Lt, Kl = kr;
function Wl(e, t) {
  return e && Fl(t, Kl(t), e);
}
var Ql = Wl;
function Jl(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var Yl = Jl, Xl = rt, ed = xr, td = Yl, rd = Object.prototype, nd = rd.hasOwnProperty;
function ad(e) {
  if (!Xl(e))
    return td(e);
  var t = ed(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !nd.call(e, n)) || r.push(n);
  return r;
}
var sd = ad, id = fa, od = sd, cd = pa;
function ud(e) {
  return cd(e) ? id(e, !0) : od(e);
}
var jr = ud, ld = Lt, dd = jr;
function fd(e, t) {
  return e && ld(t, dd(t), e);
}
var hd = fd, bt = { exports: {} };
bt.exports;
(function(e, t) {
  var r = z, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function u(l, f) {
    if (f)
      return l.slice();
    var _ = l.length, A = c ? c(_) : new l.constructor(_);
    return l.copy(A), A;
  }
  e.exports = u;
})(bt, bt.exports);
var pd = bt.exports;
function vd(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var md = vd;
function gd(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, s = 0, i = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (i[s++] = o);
  }
  return i;
}
var yd = gd;
function _d() {
  return [];
}
var va = _d, bd = yd, Td = va, $d = Object.prototype, Ad = $d.propertyIsEnumerable, fn = Object.getOwnPropertySymbols, Sd = fn ? function(e) {
  return e == null ? [] : (e = Object(e), bd(fn(e), function(t) {
    return Ad.call(e, t);
  }));
} : Td, Rr = Sd, Ed = Lt, wd = Rr;
function xd(e, t) {
  return Ed(e, wd(e), t);
}
var kd = xd;
function jd(e, t) {
  for (var r = -1, n = t.length, s = e.length; ++r < n; )
    e[s + r] = t[r];
  return e;
}
var ma = jd, Rd = ha, Od = Rd(Object.getPrototypeOf, Object), ga = Od, Cd = ma, Id = ga, Md = Rr, Nd = va, Pd = Object.getOwnPropertySymbols, Vd = Pd ? function(e) {
  for (var t = []; e; )
    Cd(t, Md(e)), e = Id(e);
  return t;
} : Nd, ya = Vd, Dd = Lt, Ld = ya;
function Ud(e, t) {
  return Dd(e, Ld(e), t);
}
var Zd = Ud, Hd = ma, qd = Ut;
function Bd(e, t, r) {
  var n = t(e);
  return qd(e) ? n : Hd(n, r(e));
}
var _a = Bd, Gd = _a, zd = Rr, Fd = kr;
function Kd(e) {
  return Gd(e, Fd, zd);
}
var ba = Kd, Wd = _a, Qd = ya, Jd = jr;
function Yd(e) {
  return Wd(e, Jd, Qd);
}
var Xd = Yd, ef = le, tf = z, rf = ef(tf, "DataView"), nf = rf, af = le, sf = z, of = af(sf, "Promise"), cf = of, uf = le, lf = z, df = uf(lf, "Set"), ff = df, hf = le, pf = z, vf = hf(pf, "WeakMap"), mf = vf, cr = nf, ur = Ar, lr = cf, dr = ff, fr = mf, Ta = Pt, je = aa, hn = "[object Map]", gf = "[object Object]", pn = "[object Promise]", vn = "[object Set]", mn = "[object WeakMap]", gn = "[object DataView]", yf = je(cr), _f = je(ur), bf = je(lr), Tf = je(dr), $f = je(fr), ie = Ta;
(cr && ie(new cr(new ArrayBuffer(1))) != gn || ur && ie(new ur()) != hn || lr && ie(lr.resolve()) != pn || dr && ie(new dr()) != vn || fr && ie(new fr()) != mn) && (ie = function(e) {
  var t = Ta(e), r = t == gf ? e.constructor : void 0, n = r ? je(r) : "";
  if (n)
    switch (n) {
      case yf:
        return gn;
      case _f:
        return hn;
      case bf:
        return pn;
      case Tf:
        return vn;
      case $f:
        return mn;
    }
  return t;
});
var Zt = ie, Af = Object.prototype, Sf = Af.hasOwnProperty;
function Ef(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && Sf.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var wf = Ef, xf = z, kf = xf.Uint8Array, $a = kf, yn = $a;
function jf(e) {
  var t = new e.constructor(e.byteLength);
  return new yn(t).set(new yn(e)), t;
}
var Or = jf, Rf = Or;
function Of(e, t) {
  var r = t ? Rf(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var Cf = Of, If = /\w*$/;
function Mf(e) {
  var t = new e.constructor(e.source, If.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Nf = Mf, _n = Nt, bn = _n ? _n.prototype : void 0, Tn = bn ? bn.valueOf : void 0;
function Pf(e) {
  return Tn ? Object(Tn.call(e)) : {};
}
var Vf = Pf, Df = Or;
function Lf(e, t) {
  var r = t ? Df(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var Uf = Lf, Zf = Or, Hf = Cf, qf = Nf, Bf = Vf, Gf = Uf, zf = "[object Boolean]", Ff = "[object Date]", Kf = "[object Map]", Wf = "[object Number]", Qf = "[object RegExp]", Jf = "[object Set]", Yf = "[object String]", Xf = "[object Symbol]", eh = "[object ArrayBuffer]", th = "[object DataView]", rh = "[object Float32Array]", nh = "[object Float64Array]", ah = "[object Int8Array]", sh = "[object Int16Array]", ih = "[object Int32Array]", oh = "[object Uint8Array]", ch = "[object Uint8ClampedArray]", uh = "[object Uint16Array]", lh = "[object Uint32Array]";
function dh(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case eh:
      return Zf(e);
    case zf:
    case Ff:
      return new n(+e);
    case th:
      return Hf(e, r);
    case rh:
    case nh:
    case ah:
    case sh:
    case ih:
    case oh:
    case ch:
    case uh:
    case lh:
      return Gf(e, r);
    case Kf:
      return new n();
    case Wf:
    case Yf:
      return new n(e);
    case Qf:
      return qf(e);
    case Jf:
      return new n();
    case Xf:
      return Bf(e);
  }
}
var fh = dh, hh = rt, $n = Object.create, ph = /* @__PURE__ */ function() {
  function e() {
  }
  return function(t) {
    if (!hh(t))
      return {};
    if ($n)
      return $n(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), vh = ph, mh = vh, gh = ga, yh = xr;
function _h(e) {
  return typeof e.constructor == "function" && !yh(e) ? mh(gh(e)) : {};
}
var bh = _h, Th = Zt, $h = ke, Ah = "[object Map]";
function Sh(e) {
  return $h(e) && Th(e) == Ah;
}
var Eh = Sh, wh = Eh, xh = Er, An = wr, Sn = An && An.isMap, kh = Sn ? xh(Sn) : wh, jh = kh, Rh = Zt, Oh = ke, Ch = "[object Set]";
function Ih(e) {
  return Oh(e) && Rh(e) == Ch;
}
var Mh = Ih, Nh = Mh, Ph = Er, En = wr, wn = En && En.isSet, Vh = wn ? Ph(wn) : Nh, Dh = Vh, Lh = ia, Uh = su, Zh = ca, Hh = Ql, qh = hd, Bh = pd, Gh = md, zh = kd, Fh = Zd, Kh = ba, Wh = Xd, Qh = Zt, Jh = wf, Yh = fh, Xh = bh, ep = Ut, tp = Sr, rp = jh, np = rt, ap = Dh, sp = kr, ip = jr, op = 1, cp = 2, up = 4, Aa = "[object Arguments]", lp = "[object Array]", dp = "[object Boolean]", fp = "[object Date]", hp = "[object Error]", Sa = "[object Function]", pp = "[object GeneratorFunction]", vp = "[object Map]", mp = "[object Number]", Ea = "[object Object]", gp = "[object RegExp]", yp = "[object Set]", _p = "[object String]", bp = "[object Symbol]", Tp = "[object WeakMap]", $p = "[object ArrayBuffer]", Ap = "[object DataView]", Sp = "[object Float32Array]", Ep = "[object Float64Array]", wp = "[object Int8Array]", xp = "[object Int16Array]", kp = "[object Int32Array]", jp = "[object Uint8Array]", Rp = "[object Uint8ClampedArray]", Op = "[object Uint16Array]", Cp = "[object Uint32Array]", S = {};
S[Aa] = S[lp] = S[$p] = S[Ap] = S[dp] = S[fp] = S[Sp] = S[Ep] = S[wp] = S[xp] = S[kp] = S[vp] = S[mp] = S[Ea] = S[gp] = S[yp] = S[_p] = S[bp] = S[jp] = S[Rp] = S[Op] = S[Cp] = !0;
S[hp] = S[Sa] = S[Tp] = !1;
function gt(e, t, r, n, s, i) {
  var o, c = t & op, u = t & cp, l = t & up;
  if (r && (o = s ? r(e, n, s, i) : r(e)), o !== void 0)
    return o;
  if (!np(e))
    return e;
  var f = ep(e);
  if (f) {
    if (o = Jh(e), !c)
      return Gh(e, o);
  } else {
    var _ = Qh(e), A = _ == Sa || _ == pp;
    if (tp(e))
      return Bh(e, c);
    if (_ == Ea || _ == Aa || A && !s) {
      if (o = u || A ? {} : Xh(e), !c)
        return u ? Fh(e, qh(o, e)) : zh(e, Hh(o, e));
    } else {
      if (!S[_])
        return s ? e : {};
      o = Yh(e, _, c);
    }
  }
  i || (i = new Lh());
  var C = i.get(e);
  if (C)
    return C;
  i.set(e, o), ap(e) ? e.forEach(function(O) {
    o.add(gt(O, t, r, O, e, i));
  }) : rp(e) && e.forEach(function(O, M) {
    o.set(M, gt(O, t, r, M, e, i));
  });
  var P = l ? u ? Wh : Kh : u ? ip : sp, V = f ? void 0 : P(e);
  return Uh(V || e, function(O, M) {
    V && (M = O, O = e[M]), Zh(o, M, gt(O, t, r, M, e, i));
  }), o;
}
var Ip = gt, Mp = Ip, Np = 1, Pp = 4;
function Vp(e) {
  return Mp(e, Np | Pp);
}
var Dp = Vp;
const Lp = /* @__PURE__ */ ea(Dp);
var $;
(function(e) {
  e.assertEqual = (s) => s;
  function t(s) {
  }
  e.assertIs = t;
  function r(s) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (s) => {
    const i = {};
    for (const o of s)
      i[o] = o;
    return i;
  }, e.getValidEnumValues = (s) => {
    const i = e.objectKeys(s).filter((c) => typeof s[s[c]] != "number"), o = {};
    for (const c of i)
      o[c] = s[c];
    return e.objectValues(o);
  }, e.objectValues = (s) => e.objectKeys(s).map(function(i) {
    return s[i];
  }), e.objectKeys = typeof Object.keys == "function" ? (s) => Object.keys(s) : (s) => {
    const i = [];
    for (const o in s)
      Object.prototype.hasOwnProperty.call(s, o) && i.push(o);
    return i;
  }, e.find = (s, i) => {
    for (const o of s)
      if (i(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (s) => Number.isInteger(s) : (s) => typeof s == "number" && isFinite(s) && Math.floor(s) === s;
  function n(s, i = " | ") {
    return s.map((o) => typeof o == "string" ? `'${o}'` : o).join(i);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (s, i) => typeof i == "bigint" ? i.toString() : i;
})($ || ($ = {}));
var hr;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(hr || (hr = {}));
const p = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), Q = (e) => {
  switch (typeof e) {
    case "undefined":
      return p.undefined;
    case "string":
      return p.string;
    case "number":
      return isNaN(e) ? p.nan : p.number;
    case "boolean":
      return p.boolean;
    case "function":
      return p.function;
    case "bigint":
      return p.bigint;
    case "symbol":
      return p.symbol;
    case "object":
      return Array.isArray(e) ? p.array : e === null ? p.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? p.promise : typeof Map < "u" && e instanceof Map ? p.map : typeof Set < "u" && e instanceof Set ? p.set : typeof Date < "u" && e instanceof Date ? p.date : p.object;
    default:
      return p.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), Up = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class D extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(i) {
      return i.message;
    }, n = { _errors: [] }, s = (i) => {
      for (const o of i.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(s);
        else if (o.code === "invalid_return_type")
          s(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          s(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, u = 0;
          for (; u < o.path.length; ) {
            const l = o.path[u];
            u === o.path.length - 1 ? (c[l] = c[l] || { _errors: [] }, c[l]._errors.push(r(o))) : c[l] = c[l] || { _errors: [] }, c = c[l], u++;
          }
        }
    };
    return s(this), n;
  }
  static assert(t) {
    if (!(t instanceof D))
      throw new Error(`Not a ZodError: ${t}`);
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const s of this.issues)
      s.path.length > 0 ? (r[s.path[0]] = r[s.path[0]] || [], r[s.path[0]].push(t(s))) : n.push(t(s));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
D.create = (e) => new D(e);
const ge = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === p.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let wa = ge;
function Zp(e) {
  wa = e;
}
function Tt() {
  return wa;
}
const $t = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: s } = e, i = [...r, ...s.path || []], o = {
    ...s,
    path: i
  };
  if (s.message !== void 0)
    return {
      ...s,
      path: i,
      message: s.message
    };
  let c = "";
  const u = n.filter((l) => !!l).slice().reverse();
  for (const l of u)
    c = l(o, { data: t, defaultError: c }).message;
  return {
    ...s,
    path: i,
    message: c
  };
}, Hp = [];
function h(e, t) {
  const r = Tt(), n = $t({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      r,
      r === ge ? void 0 : ge
      // then global default map
    ].filter((s) => !!s)
  });
  e.common.issues.push(n);
}
class I {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const s of r) {
      if (s.status === "aborted")
        return g;
      s.status === "dirty" && t.dirty(), n.push(s.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const s of r) {
      const i = await s.key, o = await s.value;
      n.push({
        key: i,
        value: o
      });
    }
    return I.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const s of r) {
      const { key: i, value: o } = s;
      if (i.status === "aborted" || o.status === "aborted")
        return g;
      i.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), i.value !== "__proto__" && (typeof o.value < "u" || s.alwaysSet) && (n[i.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const g = Object.freeze({
  status: "aborted"
}), ve = (e) => ({ status: "dirty", value: e }), N = (e) => ({ status: "valid", value: e }), pr = (e) => e.status === "aborted", vr = (e) => e.status === "dirty", Ze = (e) => e.status === "valid", He = (e) => typeof Promise < "u" && e instanceof Promise;
function At(e, t, r, n) {
  if (typeof t == "function" ? e !== t || !n : !t.has(e)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return t.get(e);
}
function xa(e, t, r, n, s) {
  if (typeof t == "function" ? e !== t || !s : !t.has(e)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return t.set(e, r), r;
}
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
var Le, Ue;
class q {
  constructor(t, r, n, s) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = s;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const xn = (e, t) => {
  if (Ze(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new D(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function b(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: s } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: s } : { errorMap: (o, c) => {
    var u, l;
    const { message: f } = e;
    return o.code === "invalid_enum_value" ? { message: f ?? c.defaultError } : typeof c.data > "u" ? { message: (u = f ?? n) !== null && u !== void 0 ? u : c.defaultError } : o.code !== "invalid_type" ? { message: c.defaultError } : { message: (l = f ?? r) !== null && l !== void 0 ? l : c.defaultError };
  }, description: s };
}
class T {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return Q(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: Q(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new I(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: Q(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (He(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const s = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: Q(t)
    }, i = this._parseSync({ data: t, path: s.path, parent: s });
    return xn(s, i);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: Q(t)
    }, s = this._parse({ data: t, path: n.path, parent: n }), i = await (He(s) ? s : Promise.resolve(s));
    return xn(n, i);
  }
  refine(t, r) {
    const n = (s) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(s) : r;
    return this._refinement((s, i) => {
      const o = t(s), c = () => i.addIssue({
        code: d.custom,
        ...n(s)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((u) => u ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, s) => t(n) ? !0 : (s.addIssue(typeof r == "function" ? r(n, s) : r), !1));
  }
  _refinement(t) {
    return new Z({
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return H.create(this, this._def);
  }
  nullable() {
    return te.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return U.create(this, this._def);
  }
  promise() {
    return _e.create(this, this._def);
  }
  or(t) {
    return ze.create([this, t], this._def);
  }
  and(t) {
    return Fe.create(this, t, this._def);
  }
  transform(t) {
    return new Z({
      ...b(this._def),
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Ye({
      ...b(this._def),
      innerType: this,
      defaultValue: r,
      typeName: m.ZodDefault
    });
  }
  brand() {
    return new Cr({
      typeName: m.ZodBranded,
      type: this,
      ...b(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Xe({
      ...b(this._def),
      innerType: this,
      catchValue: r,
      typeName: m.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return nt.create(this, t);
  }
  readonly() {
    return et.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const qp = /^c[^\s-]{8,}$/i, Bp = /^[0-9a-z]+$/, Gp = /^[0-9A-HJKMNP-TV-Z]{26}$/, zp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, Fp = /^[a-z0-9_-]{21}$/i, Kp = /^[-+]?P(?!$)(?:(?:[-+]?\d+Y)|(?:[-+]?\d+[.,]\d+Y$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:(?:[-+]?\d+W)|(?:[-+]?\d+[.,]\d+W$))?(?:(?:[-+]?\d+D)|(?:[-+]?\d+[.,]\d+D$))?(?:T(?=[\d+-])(?:(?:[-+]?\d+H)|(?:[-+]?\d+[.,]\d+H$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:[-+]?\d+(?:[.,]\d+)?S)?)??$/, Wp = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, Qp = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let ar;
const Jp = /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/, Yp = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, Xp = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/, ka = "((\\d\\d[2468][048]|\\d\\d[13579][26]|\\d\\d0[48]|[02468][048]00|[13579][26]00)-02-29|\\d{4}-((0[13578]|1[02])-(0[1-9]|[12]\\d|3[01])|(0[469]|11)-(0[1-9]|[12]\\d|30)|(02)-(0[1-9]|1\\d|2[0-8])))", ev = new RegExp(`^${ka}$`);
function ja(e) {
  let t = "([01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d";
  return e.precision ? t = `${t}\\.\\d{${e.precision}}` : e.precision == null && (t = `${t}(\\.\\d+)?`), t;
}
function tv(e) {
  return new RegExp(`^${ja(e)}$`);
}
function Ra(e) {
  let t = `${ka}T${ja(e)}`;
  const r = [];
  return r.push(e.local ? "Z?" : "Z"), e.offset && r.push("([+-]\\d{2}:?\\d{2})"), t = `${t}(${r.join("|")})`, new RegExp(`^${t}$`);
}
function rv(e, t) {
  return !!((t === "v4" || !t) && Jp.test(e) || (t === "v6" || !t) && Yp.test(e));
}
class L extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== p.string) {
      const i = this._getOrReturnCtx(t);
      return h(i, {
        code: d.invalid_type,
        expected: p.string,
        received: i.parsedType
      }), g;
    }
    const n = new I();
    let s;
    for (const i of this._def.checks)
      if (i.kind === "min")
        t.data.length < i.value && (s = this._getOrReturnCtx(t, s), h(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "max")
        t.data.length > i.value && (s = this._getOrReturnCtx(t, s), h(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "length") {
        const o = t.data.length > i.value, c = t.data.length < i.value;
        (o || c) && (s = this._getOrReturnCtx(t, s), o ? h(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }) : c && h(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }), n.dirty());
      } else if (i.kind === "email")
        Wp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
          validation: "email",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "emoji")
        ar || (ar = new RegExp(Qp, "u")), ar.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
          validation: "emoji",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "uuid")
        zp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
          validation: "uuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "nanoid")
        Fp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
          validation: "nanoid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid")
        qp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
          validation: "cuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid2")
        Bp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
          validation: "cuid2",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "ulid")
        Gp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
          validation: "ulid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "url")
        try {
          new URL(t.data);
        } catch {
          s = this._getOrReturnCtx(t, s), h(s, {
            validation: "url",
            code: d.invalid_string,
            message: i.message
          }), n.dirty();
        }
      else i.kind === "regex" ? (i.regex.lastIndex = 0, i.regex.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
        validation: "regex",
        code: d.invalid_string,
        message: i.message
      }), n.dirty())) : i.kind === "trim" ? t.data = t.data.trim() : i.kind === "includes" ? t.data.includes(i.value, i.position) || (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.invalid_string,
        validation: { includes: i.value, position: i.position },
        message: i.message
      }), n.dirty()) : i.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : i.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : i.kind === "startsWith" ? t.data.startsWith(i.value) || (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.invalid_string,
        validation: { startsWith: i.value },
        message: i.message
      }), n.dirty()) : i.kind === "endsWith" ? t.data.endsWith(i.value) || (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.invalid_string,
        validation: { endsWith: i.value },
        message: i.message
      }), n.dirty()) : i.kind === "datetime" ? Ra(i).test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.invalid_string,
        validation: "datetime",
        message: i.message
      }), n.dirty()) : i.kind === "date" ? ev.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.invalid_string,
        validation: "date",
        message: i.message
      }), n.dirty()) : i.kind === "time" ? tv(i).test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.invalid_string,
        validation: "time",
        message: i.message
      }), n.dirty()) : i.kind === "duration" ? Kp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
        validation: "duration",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : i.kind === "ip" ? rv(t.data, i.version) || (s = this._getOrReturnCtx(t, s), h(s, {
        validation: "ip",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : i.kind === "base64" ? Xp.test(t.data) || (s = this._getOrReturnCtx(t, s), h(s, {
        validation: "base64",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : $.assertNever(i);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((s) => t.test(s), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new L({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  nanoid(t) {
    return this._addCheck({ kind: "nanoid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  base64(t) {
    return this._addCheck({ kind: "base64", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r, n;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      local: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      local: (n = t == null ? void 0 : t.local) !== null && n !== void 0 ? n : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  date(t) {
    return this._addCheck({ kind: "date", message: t });
  }
  time(t) {
    return typeof t == "string" ? this._addCheck({
      kind: "time",
      precision: null,
      message: t
    }) : this._addCheck({
      kind: "time",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  duration(t) {
    return this._addCheck({ kind: "duration", ...v.errToObj(t) });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isDate() {
    return !!this._def.checks.find((t) => t.kind === "date");
  }
  get isTime() {
    return !!this._def.checks.find((t) => t.kind === "time");
  }
  get isDuration() {
    return !!this._def.checks.find((t) => t.kind === "duration");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isNANOID() {
    return !!this._def.checks.find((t) => t.kind === "nanoid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get isBase64() {
    return !!this._def.checks.find((t) => t.kind === "base64");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
L.create = (e) => {
  var t;
  return new L({
    checks: [],
    typeName: m.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
function nv(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, s = r > n ? r : n, i = parseInt(e.toFixed(s).replace(".", "")), o = parseInt(t.toFixed(s).replace(".", ""));
  return i % o / Math.pow(10, s);
}
class Y extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== p.number) {
      const i = this._getOrReturnCtx(t);
      return h(i, {
        code: d.invalid_type,
        expected: p.number,
        received: i.parsedType
      }), g;
    }
    let n;
    const s = new I();
    for (const i of this._def.checks)
      i.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: i.message
      }), s.dirty()) : i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.too_small,
        minimum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.too_big,
        maximum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? nv(t.data, i.value) !== 0 && (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : i.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.not_finite,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new Y({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new Y({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
Y.create = (e) => new Y({
  checks: [],
  typeName: m.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class X extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== p.bigint) {
      const i = this._getOrReturnCtx(t);
      return h(i, {
        code: d.invalid_type,
        expected: p.bigint,
        received: i.parsedType
      }), g;
    }
    let n;
    const s = new I();
    for (const i of this._def.checks)
      i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.too_small,
        type: "bigint",
        minimum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.too_big,
        type: "bigint",
        maximum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? t.data % i.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), h(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new X({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new X({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
X.create = (e) => {
  var t;
  return new X({
    checks: [],
    typeName: m.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
class qe extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== p.boolean) {
      const n = this._getOrReturnCtx(t);
      return h(n, {
        code: d.invalid_type,
        expected: p.boolean,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
qe.create = (e) => new qe({
  typeName: m.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class ce extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== p.date) {
      const i = this._getOrReturnCtx(t);
      return h(i, {
        code: d.invalid_type,
        expected: p.date,
        received: i.parsedType
      }), g;
    }
    if (isNaN(t.data.getTime())) {
      const i = this._getOrReturnCtx(t);
      return h(i, {
        code: d.invalid_date
      }), g;
    }
    const n = new I();
    let s;
    for (const i of this._def.checks)
      i.kind === "min" ? t.data.getTime() < i.value && (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.too_small,
        message: i.message,
        inclusive: !0,
        exact: !1,
        minimum: i.value,
        type: "date"
      }), n.dirty()) : i.kind === "max" ? t.data.getTime() > i.value && (s = this._getOrReturnCtx(t, s), h(s, {
        code: d.too_big,
        message: i.message,
        inclusive: !0,
        exact: !1,
        maximum: i.value,
        type: "date"
      }), n.dirty()) : $.assertNever(i);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ce({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ce.create = (e) => new ce({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: m.ZodDate,
  ...b(e)
});
class St extends T {
  _parse(t) {
    if (this._getType(t) !== p.symbol) {
      const n = this._getOrReturnCtx(t);
      return h(n, {
        code: d.invalid_type,
        expected: p.symbol,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
St.create = (e) => new St({
  typeName: m.ZodSymbol,
  ...b(e)
});
class Be extends T {
  _parse(t) {
    if (this._getType(t) !== p.undefined) {
      const n = this._getOrReturnCtx(t);
      return h(n, {
        code: d.invalid_type,
        expected: p.undefined,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
Be.create = (e) => new Be({
  typeName: m.ZodUndefined,
  ...b(e)
});
class Ge extends T {
  _parse(t) {
    if (this._getType(t) !== p.null) {
      const n = this._getOrReturnCtx(t);
      return h(n, {
        code: d.invalid_type,
        expected: p.null,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
Ge.create = (e) => new Ge({
  typeName: m.ZodNull,
  ...b(e)
});
class ye extends T {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
ye.create = (e) => new ye({
  typeName: m.ZodAny,
  ...b(e)
});
class oe extends T {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
oe.create = (e) => new oe({
  typeName: m.ZodUnknown,
  ...b(e)
});
class K extends T {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return h(r, {
      code: d.invalid_type,
      expected: p.never,
      received: r.parsedType
    }), g;
  }
}
K.create = (e) => new K({
  typeName: m.ZodNever,
  ...b(e)
});
class Et extends T {
  _parse(t) {
    if (this._getType(t) !== p.undefined) {
      const n = this._getOrReturnCtx(t);
      return h(n, {
        code: d.invalid_type,
        expected: p.void,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
Et.create = (e) => new Et({
  typeName: m.ZodVoid,
  ...b(e)
});
class U extends T {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), s = this._def;
    if (r.parsedType !== p.array)
      return h(r, {
        code: d.invalid_type,
        expected: p.array,
        received: r.parsedType
      }), g;
    if (s.exactLength !== null) {
      const o = r.data.length > s.exactLength.value, c = r.data.length < s.exactLength.value;
      (o || c) && (h(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? s.exactLength.value : void 0,
        maximum: o ? s.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: s.exactLength.message
      }), n.dirty());
    }
    if (s.minLength !== null && r.data.length < s.minLength.value && (h(r, {
      code: d.too_small,
      minimum: s.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.minLength.message
    }), n.dirty()), s.maxLength !== null && r.data.length > s.maxLength.value && (h(r, {
      code: d.too_big,
      maximum: s.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => s.type._parseAsync(new q(r, o, r.path, c)))).then((o) => I.mergeArray(n, o));
    const i = [...r.data].map((o, c) => s.type._parseSync(new q(r, o, r.path, c)));
    return I.mergeArray(n, i);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new U({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new U({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new U({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
U.create = (e, t) => new U({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: m.ZodArray,
  ...b(t)
});
function pe(e) {
  if (e instanceof x) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = H.create(pe(n));
    }
    return new x({
      ...e._def,
      shape: () => t
    });
  } else return e instanceof U ? new U({
    ...e._def,
    type: pe(e.element)
  }) : e instanceof H ? H.create(pe(e.unwrap())) : e instanceof te ? te.create(pe(e.unwrap())) : e instanceof B ? B.create(e.items.map((t) => pe(t))) : e;
}
class x extends T {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== p.object) {
      const l = this._getOrReturnCtx(t);
      return h(l, {
        code: d.invalid_type,
        expected: p.object,
        received: l.parsedType
      }), g;
    }
    const { status: n, ctx: s } = this._processInputParams(t), { shape: i, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof K && this._def.unknownKeys === "strip"))
      for (const l in s.data)
        o.includes(l) || c.push(l);
    const u = [];
    for (const l of o) {
      const f = i[l], _ = s.data[l];
      u.push({
        key: { status: "valid", value: l },
        value: f._parse(new q(s, _, s.path, l)),
        alwaysSet: l in s.data
      });
    }
    if (this._def.catchall instanceof K) {
      const l = this._def.unknownKeys;
      if (l === "passthrough")
        for (const f of c)
          u.push({
            key: { status: "valid", value: f },
            value: { status: "valid", value: s.data[f] }
          });
      else if (l === "strict")
        c.length > 0 && (h(s, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (l !== "strip") throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const l = this._def.catchall;
      for (const f of c) {
        const _ = s.data[f];
        u.push({
          key: { status: "valid", value: f },
          value: l._parse(
            new q(s, _, s.path, f)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: f in s.data
        });
      }
    }
    return s.common.async ? Promise.resolve().then(async () => {
      const l = [];
      for (const f of u) {
        const _ = await f.key, A = await f.value;
        l.push({
          key: _,
          value: A,
          alwaysSet: f.alwaysSet
        });
      }
      return l;
    }).then((l) => I.mergeObjectSync(n, l)) : I.mergeObjectSync(n, u);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new x({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var s, i, o, c;
          const u = (o = (i = (s = this._def).errorMap) === null || i === void 0 ? void 0 : i.call(s, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : u
          } : {
            message: u
          };
        }
      } : {}
    });
  }
  strip() {
    return new x({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new x({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new x({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new x({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: m.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new x({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return pe(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const s = this.shape[n];
      t && !t[n] ? r[n] = s : r[n] = s.optional();
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let i = this.shape[n];
        for (; i instanceof H; )
          i = i._def.innerType;
        r[n] = i;
      }
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return Oa($.objectKeys(this.shape));
  }
}
x.create = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strip",
  catchall: K.create(),
  typeName: m.ZodObject,
  ...b(t)
});
x.strictCreate = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strict",
  catchall: K.create(),
  typeName: m.ZodObject,
  ...b(t)
});
x.lazycreate = (e, t) => new x({
  shape: e,
  unknownKeys: "strip",
  catchall: K.create(),
  typeName: m.ZodObject,
  ...b(t)
});
class ze extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function s(i) {
      for (const c of i)
        if (c.result.status === "valid")
          return c.result;
      for (const c of i)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = i.map((c) => new D(c.ctx.common.issues));
      return h(r, {
        code: d.invalid_union,
        unionErrors: o
      }), g;
    }
    if (r.common.async)
      return Promise.all(n.map(async (i) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await i._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(s);
    {
      let i;
      const o = [];
      for (const u of n) {
        const l = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, f = u._parseSync({
          data: r.data,
          path: r.path,
          parent: l
        });
        if (f.status === "valid")
          return f;
        f.status === "dirty" && !i && (i = { result: f, ctx: l }), l.common.issues.length && o.push(l.common.issues);
      }
      if (i)
        return r.common.issues.push(...i.ctx.common.issues), i.result;
      const c = o.map((u) => new D(u));
      return h(r, {
        code: d.invalid_union,
        unionErrors: c
      }), g;
    }
  }
  get options() {
    return this._def.options;
  }
}
ze.create = (e, t) => new ze({
  options: e,
  typeName: m.ZodUnion,
  ...b(t)
});
const F = (e) => e instanceof We ? F(e.schema) : e instanceof Z ? F(e.innerType()) : e instanceof Qe ? [e.value] : e instanceof ee ? e.options : e instanceof Je ? $.objectValues(e.enum) : e instanceof Ye ? F(e._def.innerType) : e instanceof Be ? [void 0] : e instanceof Ge ? [null] : e instanceof H ? [void 0, ...F(e.unwrap())] : e instanceof te ? [null, ...F(e.unwrap())] : e instanceof Cr || e instanceof et ? F(e.unwrap()) : e instanceof Xe ? F(e._def.innerType) : [];
class Ht extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== p.object)
      return h(r, {
        code: d.invalid_type,
        expected: p.object,
        received: r.parsedType
      }), g;
    const n = this.discriminator, s = r.data[n], i = this.optionsMap.get(s);
    return i ? r.common.async ? i._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : i._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (h(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), g);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const s = /* @__PURE__ */ new Map();
    for (const i of r) {
      const o = F(i.shape[t]);
      if (!o.length)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (s.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        s.set(c, i);
      }
    }
    return new Ht({
      typeName: m.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: s,
      ...b(n)
    });
  }
}
function mr(e, t) {
  const r = Q(e), n = Q(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === p.object && n === p.object) {
    const s = $.objectKeys(t), i = $.objectKeys(e).filter((c) => s.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of i) {
      const u = mr(e[c], t[c]);
      if (!u.valid)
        return { valid: !1 };
      o[c] = u.data;
    }
    return { valid: !0, data: o };
  } else if (r === p.array && n === p.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const s = [];
    for (let i = 0; i < e.length; i++) {
      const o = e[i], c = t[i], u = mr(o, c);
      if (!u.valid)
        return { valid: !1 };
      s.push(u.data);
    }
    return { valid: !0, data: s };
  } else return r === p.date && n === p.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Fe extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = (i, o) => {
      if (pr(i) || pr(o))
        return g;
      const c = mr(i.value, o.value);
      return c.valid ? ((vr(i) || vr(o)) && r.dirty(), { status: r.value, value: c.data }) : (h(n, {
        code: d.invalid_intersection_types
      }), g);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([i, o]) => s(i, o)) : s(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Fe.create = (e, t, r) => new Fe({
  left: e,
  right: t,
  typeName: m.ZodIntersection,
  ...b(r)
});
class B extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== p.array)
      return h(n, {
        code: d.invalid_type,
        expected: p.array,
        received: n.parsedType
      }), g;
    if (n.data.length < this._def.items.length)
      return h(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), g;
    !this._def.rest && n.data.length > this._def.items.length && (h(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const i = [...n.data].map((o, c) => {
      const u = this._def.items[c] || this._def.rest;
      return u ? u._parse(new q(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(i).then((o) => I.mergeArray(r, o)) : I.mergeArray(r, i);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new B({
      ...this._def,
      rest: t
    });
  }
}
B.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new B({
    items: e,
    typeName: m.ZodTuple,
    rest: null,
    ...b(t)
  });
};
class Ke extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== p.object)
      return h(n, {
        code: d.invalid_type,
        expected: p.object,
        received: n.parsedType
      }), g;
    const s = [], i = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      s.push({
        key: i._parse(new q(n, c, n.path, c)),
        value: o._parse(new q(n, n.data[c], n.path, c)),
        alwaysSet: c in n.data
      });
    return n.common.async ? I.mergeObjectAsync(r, s) : I.mergeObjectSync(r, s);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof T ? new Ke({
      keyType: t,
      valueType: r,
      typeName: m.ZodRecord,
      ...b(n)
    }) : new Ke({
      keyType: L.create(),
      valueType: t,
      typeName: m.ZodRecord,
      ...b(r)
    });
  }
}
class wt extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== p.map)
      return h(n, {
        code: d.invalid_type,
        expected: p.map,
        received: n.parsedType
      }), g;
    const s = this._def.keyType, i = this._def.valueType, o = [...n.data.entries()].map(([c, u], l) => ({
      key: s._parse(new q(n, c, n.path, [l, "key"])),
      value: i._parse(new q(n, u, n.path, [l, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const u of o) {
          const l = await u.key, f = await u.value;
          if (l.status === "aborted" || f.status === "aborted")
            return g;
          (l.status === "dirty" || f.status === "dirty") && r.dirty(), c.set(l.value, f.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const u of o) {
        const l = u.key, f = u.value;
        if (l.status === "aborted" || f.status === "aborted")
          return g;
        (l.status === "dirty" || f.status === "dirty") && r.dirty(), c.set(l.value, f.value);
      }
      return { status: r.value, value: c };
    }
  }
}
wt.create = (e, t, r) => new wt({
  valueType: t,
  keyType: e,
  typeName: m.ZodMap,
  ...b(r)
});
class ue extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== p.set)
      return h(n, {
        code: d.invalid_type,
        expected: p.set,
        received: n.parsedType
      }), g;
    const s = this._def;
    s.minSize !== null && n.data.size < s.minSize.value && (h(n, {
      code: d.too_small,
      minimum: s.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.minSize.message
    }), r.dirty()), s.maxSize !== null && n.data.size > s.maxSize.value && (h(n, {
      code: d.too_big,
      maximum: s.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.maxSize.message
    }), r.dirty());
    const i = this._def.valueType;
    function o(u) {
      const l = /* @__PURE__ */ new Set();
      for (const f of u) {
        if (f.status === "aborted")
          return g;
        f.status === "dirty" && r.dirty(), l.add(f.value);
      }
      return { status: r.value, value: l };
    }
    const c = [...n.data.values()].map((u, l) => i._parse(new q(n, u, n.path, l)));
    return n.common.async ? Promise.all(c).then((u) => o(u)) : o(c);
  }
  min(t, r) {
    return new ue({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new ue({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
ue.create = (e, t) => new ue({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: m.ZodSet,
  ...b(t)
});
class me extends T {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== p.function)
      return h(r, {
        code: d.invalid_type,
        expected: p.function,
        received: r.parsedType
      }), g;
    function n(c, u) {
      return $t({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          Tt(),
          ge
        ].filter((l) => !!l),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: u
        }
      });
    }
    function s(c, u) {
      return $t({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          Tt(),
          ge
        ].filter((l) => !!l),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: u
        }
      });
    }
    const i = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof _e) {
      const c = this;
      return N(async function(...u) {
        const l = new D([]), f = await c._def.args.parseAsync(u, i).catch((C) => {
          throw l.addIssue(n(u, C)), l;
        }), _ = await Reflect.apply(o, this, f);
        return await c._def.returns._def.type.parseAsync(_, i).catch((C) => {
          throw l.addIssue(s(_, C)), l;
        });
      });
    } else {
      const c = this;
      return N(function(...u) {
        const l = c._def.args.safeParse(u, i);
        if (!l.success)
          throw new D([n(u, l.error)]);
        const f = Reflect.apply(o, this, l.data), _ = c._def.returns.safeParse(f, i);
        if (!_.success)
          throw new D([s(f, _.error)]);
        return _.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new me({
      ...this._def,
      args: B.create(t).rest(oe.create())
    });
  }
  returns(t) {
    return new me({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new me({
      args: t || B.create([]).rest(oe.create()),
      returns: r || oe.create(),
      typeName: m.ZodFunction,
      ...b(n)
    });
  }
}
class We extends T {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
We.create = (e, t) => new We({
  getter: e,
  typeName: m.ZodLazy,
  ...b(t)
});
class Qe extends T {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return h(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), g;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Qe.create = (e, t) => new Qe({
  value: e,
  typeName: m.ZodLiteral,
  ...b(t)
});
function Oa(e, t) {
  return new ee({
    values: e,
    typeName: m.ZodEnum,
    ...b(t)
  });
}
class ee extends T {
  constructor() {
    super(...arguments), Le.set(this, void 0);
  }
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return h(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (At(this, Le) || xa(this, Le, new Set(this._def.values)), !At(this, Le).has(t.data)) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return h(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), g;
    }
    return N(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t, r = this._def) {
    return ee.create(t, {
      ...this._def,
      ...r
    });
  }
  exclude(t, r = this._def) {
    return ee.create(this.options.filter((n) => !t.includes(n)), {
      ...this._def,
      ...r
    });
  }
}
Le = /* @__PURE__ */ new WeakMap();
ee.create = Oa;
class Je extends T {
  constructor() {
    super(...arguments), Ue.set(this, void 0);
  }
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== p.string && n.parsedType !== p.number) {
      const s = $.objectValues(r);
      return h(n, {
        expected: $.joinValues(s),
        received: n.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (At(this, Ue) || xa(this, Ue, new Set($.getValidEnumValues(this._def.values))), !At(this, Ue).has(t.data)) {
      const s = $.objectValues(r);
      return h(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: s
      }), g;
    }
    return N(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
Ue = /* @__PURE__ */ new WeakMap();
Je.create = (e, t) => new Je({
  values: e,
  typeName: m.ZodNativeEnum,
  ...b(t)
});
class _e extends T {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== p.promise && r.common.async === !1)
      return h(r, {
        code: d.invalid_type,
        expected: p.promise,
        received: r.parsedType
      }), g;
    const n = r.parsedType === p.promise ? r.data : Promise.resolve(r.data);
    return N(n.then((s) => this._def.type.parseAsync(s, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
_e.create = (e, t) => new _e({
  type: e,
  typeName: m.ZodPromise,
  ...b(t)
});
class Z extends T {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === m.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = this._def.effect || null, i = {
      addIssue: (o) => {
        h(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (i.addIssue = i.addIssue.bind(i), s.type === "preprocess") {
      const o = s.transform(n.data, i);
      if (n.common.async)
        return Promise.resolve(o).then(async (c) => {
          if (r.value === "aborted")
            return g;
          const u = await this._def.schema._parseAsync({
            data: c,
            path: n.path,
            parent: n
          });
          return u.status === "aborted" ? g : u.status === "dirty" || r.value === "dirty" ? ve(u.value) : u;
        });
      {
        if (r.value === "aborted")
          return g;
        const c = this._def.schema._parseSync({
          data: o,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? g : c.status === "dirty" || r.value === "dirty" ? ve(c.value) : c;
      }
    }
    if (s.type === "refinement") {
      const o = (c) => {
        const u = s.refinement(c, i);
        if (n.common.async)
          return Promise.resolve(u);
        if (u instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (s.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!Ze(o))
          return o;
        const c = s.transform(o.value, i);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => Ze(o) ? Promise.resolve(s.transform(o.value, i)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(s);
  }
}
Z.create = (e, t, r) => new Z({
  schema: e,
  typeName: m.ZodEffects,
  effect: t,
  ...b(r)
});
Z.createWithPreprocess = (e, t, r) => new Z({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: m.ZodEffects,
  ...b(r)
});
class H extends T {
  _parse(t) {
    return this._getType(t) === p.undefined ? N(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
H.create = (e, t) => new H({
  innerType: e,
  typeName: m.ZodOptional,
  ...b(t)
});
class te extends T {
  _parse(t) {
    return this._getType(t) === p.null ? N(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
te.create = (e, t) => new te({
  innerType: e,
  typeName: m.ZodNullable,
  ...b(t)
});
class Ye extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === p.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Ye.create = (e, t) => new Ye({
  innerType: e,
  typeName: m.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ...b(t)
});
class Xe extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, s = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return He(s) ? s.then((i) => ({
      status: "valid",
      value: i.status === "valid" ? i.value : this._def.catchValue({
        get error() {
          return new D(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new D(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
Xe.create = (e, t) => new Xe({
  innerType: e,
  typeName: m.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ...b(t)
});
class xt extends T {
  _parse(t) {
    if (this._getType(t) !== p.nan) {
      const n = this._getOrReturnCtx(t);
      return h(n, {
        code: d.invalid_type,
        expected: p.nan,
        received: n.parsedType
      }), g;
    }
    return { status: "valid", value: t.data };
  }
}
xt.create = (e) => new xt({
  typeName: m.ZodNaN,
  ...b(e)
});
const av = Symbol("zod_brand");
class Cr extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class nt extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const i = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return i.status === "aborted" ? g : i.status === "dirty" ? (r.dirty(), ve(i.value)) : this._def.out._parseAsync({
          data: i.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const s = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return s.status === "aborted" ? g : s.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: s.value
      }) : this._def.out._parseSync({
        data: s.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new nt({
      in: t,
      out: r,
      typeName: m.ZodPipeline
    });
  }
}
class et extends T {
  _parse(t) {
    const r = this._def.innerType._parse(t), n = (s) => (Ze(s) && (s.value = Object.freeze(s.value)), s);
    return He(r) ? r.then((s) => n(s)) : n(r);
  }
  unwrap() {
    return this._def.innerType;
  }
}
et.create = (e, t) => new et({
  innerType: e,
  typeName: m.ZodReadonly,
  ...b(t)
});
function Ca(e, t = {}, r) {
  return e ? ye.create().superRefine((n, s) => {
    var i, o;
    if (!e(n)) {
      const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, u = (o = (i = c.fatal) !== null && i !== void 0 ? i : r) !== null && o !== void 0 ? o : !0, l = typeof c == "string" ? { message: c } : c;
      s.addIssue({ code: "custom", ...l, fatal: u });
    }
  }) : ye.create();
}
const sv = {
  object: x.lazycreate
};
var m;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(m || (m = {}));
const iv = (e, t = {
  message: `Input not instance of ${e.name}`
}) => Ca((r) => r instanceof e, t), Ia = L.create, Ma = Y.create, ov = xt.create, cv = X.create, Na = qe.create, uv = ce.create, lv = St.create, dv = Be.create, fv = Ge.create, hv = ye.create, pv = oe.create, vv = K.create, mv = Et.create, gv = U.create, yv = x.create, _v = x.strictCreate, bv = ze.create, Tv = Ht.create, $v = Fe.create, Av = B.create, Sv = Ke.create, Ev = wt.create, wv = ue.create, xv = me.create, kv = We.create, jv = Qe.create, Rv = ee.create, Ov = Je.create, Cv = _e.create, kn = Z.create, Iv = H.create, Mv = te.create, Nv = Z.createWithPreprocess, Pv = nt.create, Vv = () => Ia().optional(), Dv = () => Ma().optional(), Lv = () => Na().optional(), Uv = {
  string: (e) => L.create({ ...e, coerce: !0 }),
  number: (e) => Y.create({ ...e, coerce: !0 }),
  boolean: (e) => qe.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => X.create({ ...e, coerce: !0 }),
  date: (e) => ce.create({ ...e, coerce: !0 })
}, Zv = g;
var a = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: ge,
  setErrorMap: Zp,
  getErrorMap: Tt,
  makeIssue: $t,
  EMPTY_PATH: Hp,
  addIssueToContext: h,
  ParseStatus: I,
  INVALID: g,
  DIRTY: ve,
  OK: N,
  isAborted: pr,
  isDirty: vr,
  isValid: Ze,
  isAsync: He,
  get util() {
    return $;
  },
  get objectUtil() {
    return hr;
  },
  ZodParsedType: p,
  getParsedType: Q,
  ZodType: T,
  datetimeRegex: Ra,
  ZodString: L,
  ZodNumber: Y,
  ZodBigInt: X,
  ZodBoolean: qe,
  ZodDate: ce,
  ZodSymbol: St,
  ZodUndefined: Be,
  ZodNull: Ge,
  ZodAny: ye,
  ZodUnknown: oe,
  ZodNever: K,
  ZodVoid: Et,
  ZodArray: U,
  ZodObject: x,
  ZodUnion: ze,
  ZodDiscriminatedUnion: Ht,
  ZodIntersection: Fe,
  ZodTuple: B,
  ZodRecord: Ke,
  ZodMap: wt,
  ZodSet: ue,
  ZodFunction: me,
  ZodLazy: We,
  ZodLiteral: Qe,
  ZodEnum: ee,
  ZodNativeEnum: Je,
  ZodPromise: _e,
  ZodEffects: Z,
  ZodTransformer: Z,
  ZodOptional: H,
  ZodNullable: te,
  ZodDefault: Ye,
  ZodCatch: Xe,
  ZodNaN: xt,
  BRAND: av,
  ZodBranded: Cr,
  ZodPipeline: nt,
  ZodReadonly: et,
  custom: Ca,
  Schema: T,
  ZodSchema: T,
  late: sv,
  get ZodFirstPartyTypeKind() {
    return m;
  },
  coerce: Uv,
  any: hv,
  array: gv,
  bigint: cv,
  boolean: Na,
  date: uv,
  discriminatedUnion: Tv,
  effect: kn,
  enum: Rv,
  function: xv,
  instanceof: iv,
  intersection: $v,
  lazy: kv,
  literal: jv,
  map: Ev,
  nan: ov,
  nativeEnum: Ov,
  never: vv,
  null: fv,
  nullable: Mv,
  number: Ma,
  object: yv,
  oboolean: Lv,
  onumber: Dv,
  optional: Iv,
  ostring: Vv,
  pipeline: Pv,
  preprocess: Nv,
  promise: Cv,
  record: Sv,
  set: wv,
  strictObject: _v,
  string: Ia,
  symbol: lv,
  transformer: kn,
  tuple: Av,
  undefined: dv,
  union: bv,
  unknown: pv,
  void: mv,
  NEVER: Zv,
  ZodIssueCode: d,
  quotelessJson: Up,
  ZodError: D
}), w = (e) => e, Hv = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let s = e;
    for (let i = t + 1; i <= this.latestVersion; i++) {
      const o = this.versionMap[i];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: i }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: i }
        };
      s = o.up(s);
    }
    return { type: "ok", value: s };
  }
};
function at(e) {
  return new Hv(e.versionMap, e.latestVersion, e.getVersion);
}
function kt(e) {
  return a.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
var qv = "__lodash_hash_undefined__";
function Bv(e) {
  return this.__data__.set(e, qv), this;
}
var Gv = Bv;
function zv(e) {
  return this.__data__.has(e);
}
var Fv = zv, Kv = sa, Wv = Gv, Qv = Fv;
function jt(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new Kv(); ++t < r; )
    this.add(e[t]);
}
jt.prototype.add = jt.prototype.push = Wv;
jt.prototype.has = Qv;
var Jv = jt;
function Yv(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Xv = Yv;
function em(e, t) {
  return e.has(t);
}
var tm = em, rm = Jv, nm = Xv, am = tm, sm = 1, im = 2;
function om(e, t, r, n, s, i) {
  var o = r & sm, c = e.length, u = t.length;
  if (c != u && !(o && u > c))
    return !1;
  var l = i.get(e), f = i.get(t);
  if (l && f)
    return l == t && f == e;
  var _ = -1, A = !0, C = r & im ? new rm() : void 0;
  for (i.set(e, t), i.set(t, e); ++_ < c; ) {
    var P = e[_], V = t[_];
    if (n)
      var O = o ? n(V, P, _, t, e, i) : n(P, V, _, e, t, i);
    if (O !== void 0) {
      if (O)
        continue;
      A = !1;
      break;
    }
    if (C) {
      if (!nm(t, function(M, se) {
        if (!am(C, se) && (P === M || s(P, M, r, n, i)))
          return C.push(se);
      })) {
        A = !1;
        break;
      }
    } else if (!(P === V || s(P, V, r, n, i))) {
      A = !1;
      break;
    }
  }
  return i.delete(e), i.delete(t), A;
}
var Pa = om;
function cm(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, s) {
    r[++t] = [s, n];
  }), r;
}
var um = cm;
function lm(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var dm = lm, jn = Nt, Rn = $a, fm = $r, hm = Pa, pm = um, vm = dm, mm = 1, gm = 2, ym = "[object Boolean]", _m = "[object Date]", bm = "[object Error]", Tm = "[object Map]", $m = "[object Number]", Am = "[object RegExp]", Sm = "[object Set]", Em = "[object String]", wm = "[object Symbol]", xm = "[object ArrayBuffer]", km = "[object DataView]", On = jn ? jn.prototype : void 0, sr = On ? On.valueOf : void 0;
function jm(e, t, r, n, s, i, o) {
  switch (r) {
    case km:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case xm:
      return !(e.byteLength != t.byteLength || !i(new Rn(e), new Rn(t)));
    case ym:
    case _m:
    case $m:
      return fm(+e, +t);
    case bm:
      return e.name == t.name && e.message == t.message;
    case Am:
    case Em:
      return e == t + "";
    case Tm:
      var c = pm;
    case Sm:
      var u = n & mm;
      if (c || (c = vm), e.size != t.size && !u)
        return !1;
      var l = o.get(e);
      if (l)
        return l == t;
      n |= gm, o.set(e, t);
      var f = hm(c(e), c(t), n, s, i, o);
      return o.delete(e), f;
    case wm:
      if (sr)
        return sr.call(e) == sr.call(t);
  }
  return !1;
}
var Rm = jm, Cn = ba, Om = 1, Cm = Object.prototype, Im = Cm.hasOwnProperty;
function Mm(e, t, r, n, s, i) {
  var o = r & Om, c = Cn(e), u = c.length, l = Cn(t), f = l.length;
  if (u != f && !o)
    return !1;
  for (var _ = u; _--; ) {
    var A = c[_];
    if (!(o ? A in t : Im.call(t, A)))
      return !1;
  }
  var C = i.get(e), P = i.get(t);
  if (C && P)
    return C == t && P == e;
  var V = !0;
  i.set(e, t), i.set(t, e);
  for (var O = o; ++_ < u; ) {
    A = c[_];
    var M = e[A], se = t[A];
    if (n)
      var Xr = o ? n(se, M, A, t, e, i) : n(M, se, A, e, t, i);
    if (!(Xr === void 0 ? M === se || s(M, se, r, n, i) : Xr)) {
      V = !1;
      break;
    }
    O || (O = A == "constructor");
  }
  if (V && !O) {
    var ht = e.constructor, pt = t.constructor;
    ht != pt && "constructor" in e && "constructor" in t && !(typeof ht == "function" && ht instanceof ht && typeof pt == "function" && pt instanceof pt) && (V = !1);
  }
  return i.delete(e), i.delete(t), V;
}
var Nm = Mm, ir = ia, Pm = Pa, Vm = Rm, Dm = Nm, In = Zt, Mn = Ut, Nn = Sr, Lm = da, Um = 1, Pn = "[object Arguments]", Vn = "[object Array]", mt = "[object Object]", Zm = Object.prototype, Dn = Zm.hasOwnProperty;
function Hm(e, t, r, n, s, i) {
  var o = Mn(e), c = Mn(t), u = o ? Vn : In(e), l = c ? Vn : In(t);
  u = u == Pn ? mt : u, l = l == Pn ? mt : l;
  var f = u == mt, _ = l == mt, A = u == l;
  if (A && Nn(e)) {
    if (!Nn(t))
      return !1;
    o = !0, f = !1;
  }
  if (A && !f)
    return i || (i = new ir()), o || Lm(e) ? Pm(e, t, r, n, s, i) : Vm(e, t, u, r, n, s, i);
  if (!(r & Um)) {
    var C = f && Dn.call(e, "__wrapped__"), P = _ && Dn.call(t, "__wrapped__");
    if (C || P) {
      var V = C ? e.value() : e, O = P ? t.value() : t;
      return i || (i = new ir()), s(V, O, r, n, i);
    }
  }
  return A ? (i || (i = new ir()), Dm(e, t, r, n, s, i)) : !1;
}
var qm = Hm, Bm = qm, Ln = ke;
function Va(e, t, r, n, s) {
  return e === t ? !0 : e == null || t == null || !Ln(e) && !Ln(t) ? e !== e && t !== t : Bm(e, t, r, n, Va, s);
}
var Gm = Va, zm = Gm;
function Fm(e, t) {
  return zm(e, t);
}
var Km = Fm;
const Wm = /* @__PURE__ */ ea(Km), Qm = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), or = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), he = {
  equals(e, t) {
    return Wm(e, t);
  }
}, Jm = a.object({
  id: a.optional(a.string()),
  // Firebase Firestore ID
  url: a.string(),
  path: a.string(),
  headers: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  params: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  name: a.string(),
  method: a.string(),
  preRequestScript: a.string(),
  testScript: a.string(),
  contentType: a.string(),
  body: a.string(),
  rawParams: a.optional(a.string()),
  auth: a.optional(a.string()),
  httpUser: a.optional(a.string()),
  httpPassword: a.optional(a.string()),
  bearerToken: a.optional(a.string())
}), Un = w({
  initial: !0,
  schema: Jm
}), Ir = a.object({
  key: a.string(),
  active: a.boolean()
}).and(
  a.union([
    a.object({
      isFile: a.literal(!0),
      value: a.array(a.instanceof(Blob).nullable())
    }),
    a.object({
      isFile: a.literal(!1),
      value: a.string()
    })
  ])
), b_ = a.object({
  contentType: a.literal("multipart/form-data"),
  body: a.array(Ir)
}), Ym = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(Ir).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), Re = a.object({
  authType: a.literal("none")
}), Oe = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), Ce = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), Xm = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), st = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), Ie = a.object({
  authType: a.literal("inherit")
}), eg = a.discriminatedUnion("authType", [
  Re,
  Ie,
  Oe,
  Ce,
  Xm,
  st
]).and(
  a.object({
    authActive: a.boolean()
  })
), tg = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Da = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), La = a.object({
  v: a.literal("1"),
  id: a.optional(a.string()),
  // Firebase Firestore ID
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  params: tg,
  headers: Da,
  preRequestScript: a.string().catch(""),
  testScript: a.string().catch(""),
  auth: eg,
  body: Ym
});
function rg(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function ng(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const ag = w({
  initial: !1,
  schema: La,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: u
    } = e, l = `${t}${r}`, f = rg(e), _ = ng(e), A = {
      v: "1",
      endpoint: l,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: u,
      body: f,
      auth: _
    };
    return e.id && (A.id = e.id), A;
  }
}), Mr = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Ua = La.extend({
  v: a.literal("2"),
  requestVariables: Mr
}), sg = w({
  initial: !1,
  schema: Ua,
  up(e) {
    return {
      ...e,
      v: "2",
      requestVariables: []
    };
  }
}), Za = a.object({
  grantType: a.literal("AUTHORIZATION_CODE"),
  authEndpoint: a.string().trim(),
  tokenEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch(""),
  isPKCE: a.boolean(),
  codeVerifierMethod: a.union([a.literal("plain"), a.literal("S256")]).optional()
}), qt = a.object({
  grantType: a.literal("CLIENT_CREDENTIALS"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), Bt = a.object({
  grantType: a.literal("PASSWORD"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  username: a.string().trim(),
  password: a.string().trim(),
  token: a.string().catch("")
}), Gt = a.object({
  grantType: a.literal("IMPLICIT"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), zt = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Za,
    qt,
    Bt,
    Gt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), ig = a.discriminatedUnion("authType", [
  Re,
  Ie,
  Oe,
  Ce,
  zt,
  st
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ha = Ua.extend({
  v: a.literal("3"),
  auth: ig
}), og = w({
  initial: !1,
  schema: Ha,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: "3",
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: "3",
      auth: {
        ...e.auth
      }
    };
  }
}), qa = st.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), cg = a.discriminatedUnion("authType", [
  Re,
  Ie,
  Oe,
  Ce,
  zt,
  qa
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ba = Ha.extend({
  v: a.literal("4"),
  auth: cg
}), ug = w({
  schema: Ba,
  initial: !1,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: "4",
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      auth: {
        ...e.auth
      },
      v: "4"
    };
  }
}), Ga = Za.extend({
  clientSecret: a.string().optional()
}), Ft = zt.extend({
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Ga,
    qt,
    Bt,
    Gt
  ])
}), za = a.discriminatedUnion("authType", [
  Re,
  Ie,
  Oe,
  Ce,
  Ft,
  qa
]).and(
  a.object({
    authActive: a.boolean()
  })
), Fa = Ba.extend({
  v: a.literal("5"),
  auth: za
}), lg = w({
  schema: Fa,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "5"
    };
  }
}), Nr = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(Ir).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), Ka = Fa.extend({
  v: a.literal("6"),
  body: Nr
}), dg = w({
  schema: Ka,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "6"
    };
  }
}), Wa = Ga.extend({
  refreshToken: a.string().optional()
}), Qa = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Wa,
    qt,
    Bt,
    Gt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Pr = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), Kt = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), Vr = a.object({
  authType: a.literal("aws-signature"),
  accessKey: a.string().catch(""),
  secretKey: a.string().catch(""),
  region: a.string().catch(""),
  serviceName: a.string().catch(""),
  serviceToken: a.string().optional(),
  signature: a.object({}).optional(),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Ja = a.discriminatedUnion("authType", [
  Re,
  Ie,
  Oe,
  Ce,
  Qa,
  st,
  Vr
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ya = Ka.extend({
  v: a.literal("7"),
  params: Pr,
  headers: Kt,
  auth: Ja
}), fg = w({
  schema: Ya,
  initial: !1,
  up(e) {
    const t = e.params.map((n) => ({
      ...n,
      description: ""
    })), r = e.headers.map((n) => ({
      ...n,
      description: ""
    }));
    return {
      ...e,
      v: "7",
      params: t,
      headers: r
      // no need to update anything for HoppRESTAuth, because the newly added refreshToken is optional
    };
  }
}), hg = {
  // 1xx Informational
  // Request received, continuing process.[2]
  // This class of status code indicates a provisional response, consisting only of the Status-Line and optional headers, and is terminated by an empty line. Since HTTP/1.0 did not define any 1xx status codes, servers must not send a 1xx response to an HTTP/1.0 client except under experimental conditions.
  100: "Continue",
  // This means that the server has received the request headers, and that the client should proceed to send the request body (in the case of a request for which a body needs to be sent; for example, a POST request). If the request body is large, sending it to a server when a request has already been rejected based upon inappropriate headers is inefficient. To have a server check if the request could be accepted based on the request's headers alone, a client must send Expect: 100-continue as a header in its initial request[2] and check if a 100 Continue status code is received in response before continuing (or receive 417 Expectation Failed and not continue).[2]
  101: "Switching Protocols",
  // This means the requester has asked the server to switch protocols and the server is acknowledging that it will do so.[2]
  102: "Processing",
  // (WebDAV; RFC 2518) As a WebDAV request may contain many sub-requests involving file operations, it may take a long time to complete the request. This code indicates that the server has received and is processing the request, but no response is available yet.[3] This prevents the client from timing out and assuming the request was lost.
  // 2xx Success
  // This class of status codes indicates the action requested by the client was received, understood, accepted and processed successfully.
  200: "OK",
  // Standard response for successful HTTP requests. The actual response will depend on the request method used. In a GET request, the response will contain an entity corresponding to the requested resource. In a POST request the response will contain an entity describing or containing the result of the action.[2]
  201: "Created",
  // The request has been fulfilled and resulted in a new resource being created.[2]
  202: "Accepted",
  // The request has been accepted for processing, but the processing has not been completed. The request might or might not eventually be acted upon, as it might be disallowed when processing actually takes place.[2]
  203: "Non-Authoritative Information",
  // (since HTTP/1.1) The server successfully processed the request, but is returning information that may be from another source.[2]
  204: "No Content",
  // The server successfully processed the request, but is not returning any content.[2]
  205: "Reset Content",
  // The server successfully processed the request, but is not returning any content. Unlike a 204 response, this response requires that the requester reset the document view.[2]
  206: "Partial Content",
  // The server is delivering only part of the resource due to a range header sent by the client. The range header is used by tools like wget to enable resuming of interrupted downloads, or split a download into multiple simultaneous streams.[2]
  207: "Multi-Status",
  // (WebDAV; RFC 4918) The message body that follows is an XML message and can contain a number of separate response codes, depending on how many sub-requests were made.[4]
  208: "Already Reported",
  // (WebDAV; RFC 5842) The members of a DAV binding have already been enumerated in a previous reply to this request, and are not being included again.
  226: "IM Used",
  // (RFC 3229) The server has fulfilled a GET request for the resource, and the response is a representation of the result of one or more instance-manipulations applied to the current instance. [5]
  // 3xx Redirection
  // The client must take additional action to complete the request.[2]
  // This class of status code indicates that further action needs to be taken by the user agent in order to fulfil the request. The action required may be carried out by the user agent without interaction with the user if and only if the method used in the second request is GET or HEAD. A user agent should not automatically redirect a request more than five times, since such redirections usually indicate an infinite loop.
  300: "Multiple Choices",
  // Indicates multiple options for the resource that the client may follow. It, for instance, could be used to present different format options for video, list files with different extensions, or word sense disambiguation.[2]
  301: "Moved Permanently",
  // This and all future requests should be directed to the given URI.[2]
  302: "Found",
  // This is an example of industry practice contradicting the standard.[2] The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect (the original describing phrase was "Moved Temporarily"),[6] but popular browsers implemented 302 with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307 to distinguish between the two behaviours.[7] However, some Web applications and frameworks use the 302 status code as if it were the 303.[citation needed]
  303: "See Other",
  // (since HTTP/1.1) The response to the request can be found under another URI using a GET method. When received in response to a POST (or PUT/DELETE), it should be assumed that the server has received the data and the redirect should be issued with a separate GET message.[2]
  304: "Not Modified",
  // Indicates the resource has not been modified since last requested.[2] Typically, the HTTP client provides a header like the If-Modified-Since header to provide a time against which to compare. Using this saves bandwidth and reprocessing on both the server and client, as only the header data must be sent and received in comparison to the entirety of the page being re-processed by the server, then sent again using more bandwidth of the server and client.
  305: "Use Proxy",
  // (since HTTP/1.1) Many HTTP clients (such as Mozilla[8] and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.[2]
  306: "Switch Proxy",
  // No longer used.[2] Originally meant "Subsequent requests should use the specified proxy."[9]
  307: "Temporary Redirect",
  // (since HTTP/1.1) In this case, the request should be repeated with another URI; however, future requests can still use the original URI.[2] In contrast to 302, the request method should not be changed when reissuing the original request. For instance, a POST request must be repeated using another POST request.
  308: "Permanent Redirect",
  // (experimental Internet-Draft)[10] The request, and all future requests should be repeated using another URI. 307 and 308 (as proposed) parallel the behaviours of 302 and 301, but do not require the HTTP method to change. So, for example, submitting a form to a permanently redirected resource may continue smoothly.
  // 4xx Client Error
  // The 4xx class of status code is intended for cases in which the client seems to have erred. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and whether it is a temporary or permanent condition. These status codes are applicable to any request method. User agents should display any included entity to the user.
  400: "Bad Request",
  // The request cannot be fulfilled due to bad syntax.[2]
  401: "Unauthorized",
  // Similar to 403 Forbidden, but specifically for use when authentication is possible but has failed or not yet been provided.[2] The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource. See Basic access authentication and Digest access authentication.
  402: "Payment Required",
  // Reserved for future use.[2] The original intention was that this code might be used as part of some form of digital cash or micropayment scheme, but that has not happened, and this code is not usually used. As an example of its use, however, Apple's MobileMe service generates a 402 error ("httpStatusCode:402" in the Mac OS X Console log) if the MobileMe account is delinquent.[citation needed]
  403: "Forbidden",
  // The request was a legal request, but the server is refusing to respond to it.[2] Unlike a 401 Unauthorized response, authenticating will make no difference.[2]
  404: "Not Found",
  // The requested resource could not be found but may be available again in the future.[2] Subsequent requests by the client are permissible.
  405: "Method Not Allowed",
  // A request was made of a resource using a request method not supported by that resource;[2] for example, using GET on a form which requires data to be presented via POST, or using PUT on a read-only resource.
  406: "Not Acceptable",
  // The requested resource is only capable of generating content not acceptable according to the Accept headers sent in the request.[2]
  407: "Proxy Authentication Required",
  // The client must first authenticate itself with the proxy.[2]
  408: "Request Timeout",
  // The server timed out waiting for the request.[2] According to W3 HTTP specifications: "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
  409: "Conflict",
  // Indicates that the request could not be processed because of conflict in the request, such as an edit conflict.[2]
  410: "Gone",
  // Indicates that the resource requested is no longer available and will not be available again.[2] This should be used when a resource has been intentionally removed and the resource should be purged. Upon receiving a 410 status code, the client should not request the resource again in the future. Clients such as search engines should remove the resource from their indices. Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
  411: "Length Required",
  // The request did not specify the length of its content, which is required by the requested resource.[2]
  412: "Precondition Failed",
  // The server does not meet one of the preconditions that the requester put on the request.[2]
  413: "Request Entity Too Large",
  // The request is larger than the server is willing or able to process.[2]
  414: "Request-URI Too Long",
  // The URI provided was too long for the server to process.[2]
  415: "Unsupported Media Type",
  // The request entity has a media type which the server or resource does not support.[2] For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
  416: "Requested Range Not Satisfiable",
  // The client has asked for a portion of the file, but the server cannot supply that portion.[2] For example, if the client asked for a part of the file that lies beyond the end of the file.[2]
  417: "Expectation Failed",
  // The server cannot meet the requirements of the Expect request-header field.[2]
  418: "I'm a teapot",
  // (RFC 2324) This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol, and is not expected to be implemented by actual HTTP servers. However, known implementations do exist.[11]
  420: "Enhance Your Calm",
  // (Twitter) Returned by the Twitter Search and Trends API when the client is being rate limited.[12] Likely a reference to this number's association with marijuana. Other services may wish to implement the 429 Too Many Requests response code instead. The phrase "Enhance Your Calm" is a reference to Demolition Man (film). In the film, Sylvester Stallone's character John Spartan is a hot-head in a generally more subdued future, and is regularly told to "Enhance your calm" rather than a more common phrase like "calm down".
  422: "Unprocessable Entity",
  // (WebDAV; RFC 4918) The request was well-formed but was unable to be followed due to semantic errors.[4]
  423: "Locked",
  // (WebDAV; RFC 4918) The resource that is being accessed is locked.[4]
  424: "Failed Dependency",
  // (WebDAV; RFC 4918) The request failed due to failure of a previous request (e.g. a PROPPATCH).[4]
  425: "Unordered Collection",
  // (Internet draft) Defined in drafts of "WebDAV Advanced Collections Protocol",[14] but not present in "Web Distributed Authoring and Versioning (WebDAV) Ordered Collections Protocol".[15]
  426: "Upgrade Required",
  // (RFC 2817) The client should switch to a different protocol such as TLS/1.0.[16]
  428: "Precondition Required",
  // (RFC 6585) The origin server requires the request to be conditional. Intended to prevent "the 'lost update' problem, where a client GETs a resource's state, modifies it, and PUTs it back to the server, when meanwhile a third party has modified the state on the server, leading to a conflict."[17]
  429: "Too Many Requests",
  // (RFC 6585) The user has sent too many requests in a given amount of time. Intended for use with rate limiting schemes.[17]
  431: "Request Header Fields Too Large",
  // (RFC 6585) The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.[17]
  444: "No Response",
  // (Nginx) Used in Nginx logs to indicate that the server has returned no information to the client and closed the connection (useful as a deterrent for malware).
  449: "Retry With",
  // (Microsoft) A Microsoft extension. The request should be retried after performing the appropriate action.[18] Often search-engines or custom applications will ignore required parameters. Where no default action is appropriate, the Aviongoo website sends a "HTTP/1.1 449 Retry with valid parameters: param1, param2, . . ." response. The applications may choose to learn, or not.
  450: "Blocked by Windows Parental Controls",
  // (Microsoft) A Microsoft extension. This error is given when Windows Parental Controls are turned on and are blocking access to the given webpage.[19]
  451: "Unavailable For Legal Reasons",
  // (Internet draft) Defined in the internet draft "A New HTTP Status Code for Legally-restricted Resources",[20]. Intended to be used when resource access is denied for legal reasons, e.g. censorship or government-mandated blocked access. Likely a reference to the 1953 dystopian novel Fahrenheit 451, where books are outlawed.
  499: "Client Closed Request",
  // (Nginx) Used in Nginx logs to indicate when the connection has been closed by client while the server is still processing its request, making server unable to send a status code back.[21]
  // 5xx Server Error
  // The server failed to fulfill an apparently valid request.[2]
  // Response status codes beginning with the digit "5" indicate cases in which the server is aware that it has encountered an error or is otherwise incapable of performing the request. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and indicate whether it is a temporary or permanent condition. Likewise, user agents should display any included entity to the user. These response codes are applicable to any request method.
  500: "Internal Server Error",
  // A generic error message, given when no more specific message is suitable.[2]
  501: "Not Implemented",
  // The server either does not recognise the request method, or it lacks the ability to fulfill the request.[2]
  502: "Bad Gateway",
  // The server was acting as a gateway or proxy and received an invalid response from the upstream server.[2]
  503: "Service Unavailable",
  // The server is currently unavailable (because it is overloaded or down for maintenance).[2] Generally, this is a temporary state.
  504: "Gateway Timeout",
  // The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.[2]
  505: "HTTP Version Not Supported",
  // The server does not support the HTTP protocol version used in the request.[2]
  506: "Variant Also Negotiates",
  // (RFC 2295) Transparent content negotiation for the request results in a circular reference.[22]
  507: "Insufficient Storage",
  // (WebDAV; RFC 4918) The server is unable to store the representation needed to complete the request.[4]
  508: "Loop Detected",
  // (WebDAV; RFC 5842) The server detected an infinite loop while processing the request (sent in lieu of 208).
  509: "Bandwidth Limit Exceeded",
  // (Apache bw/limited extension) This status code, while used by many servers, is not specified in any RFCs.
  510: "Not Extended",
  // (RFC 2774) Further extensions to the request are required for the server to fulfill it.[23]
  511: "Network Authentication Required",
  // (RFC 6585) The client needs to authenticate to gain network access. Intended for use by intercepting proxies used to control access to the network (e.g. "captive portals" used to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).[17]
  598: "Network read timeout error",
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network read timeout behind the proxy to a client in front of the proxy.
  599: "Network connect timeout error"
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network connect timeout behind the proxy to a client in front of the proxy.
}, pg = qt.extend({
  clientSecret: a.string().optional()
}), vg = Bt.extend({
  clientSecret: a.string().optional()
}), mg = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Wa,
    pg,
    vg,
    Gt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), gg = a.object({
  authType: a.literal("digest"),
  username: a.string().catch(""),
  password: a.string().catch(""),
  realm: a.string().catch(""),
  nonce: a.string().catch(""),
  algorithm: a.enum(["MD5", "MD5-sess"]).catch("MD5"),
  qop: a.enum(["auth", "auth-int"]).catch("auth"),
  nc: a.string().catch(""),
  cnonce: a.string().catch(""),
  opaque: a.string().catch(""),
  disableRetry: a.boolean().catch(!1)
}), Wt = a.discriminatedUnion("authType", [
  Re,
  Ie,
  Oe,
  Ce,
  mg,
  st,
  Vr,
  gg
]).and(
  a.object({
    authActive: a.boolean()
  })
), yg = a.union(
  Object.keys(hg).map((e) => a.literal(parseInt(e)))
), _g = a.array(
  a.object({
    key: a.string(),
    value: a.string()
  })
), bg = a.object({
  v: a.literal("1"),
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  headers: Kt,
  params: Pr,
  body: Nr,
  auth: Wt,
  requestVariables: Mr
}), Tg = a.object({
  name: a.string(),
  originalRequest: bg,
  status: a.string(),
  code: a.optional(yg),
  headers: _g,
  body: a.string()
}), Xa = a.record(
  a.string(),
  Tg
), $g = Ya.extend({
  v: a.literal("8"),
  auth: Wt,
  responses: Xa
}), Ag = w({
  schema: $g,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "8",
      // no need to update anything for HoppRESTAuth, because we loosened the previous schema by making `clientSecret` optional
      responses: {}
    };
  }
}), Sg = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "text/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, T_ = Object.keys(
  Sg
), Eg = a.object({
  // v is a stringified number
  v: a.string().regex(/^\d+$/).transform(Number)
}), Qt = at({
  latestVersion: 8,
  versionMap: {
    0: Un,
    1: ag,
    2: sg,
    3: og,
    4: ug,
    5: lg,
    6: dg,
    7: fg,
    8: Ag
  },
  getVersion(e) {
    const t = Eg.safeParse(e);
    return t.success ? t.data.v : Un.schema.safeParse(e).success ? 0 : null;
  }
}), wg = Fs({
  id: Qm(W),
  v: W,
  auth: he,
  body: he,
  endpoint: W,
  headers: or(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    he
  ),
  params: or(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    he
  ),
  method: W,
  name: W,
  preRequestScript: W,
  testScript: W,
  requestVariables: or(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    he
  ),
  responses: he
}), es = "8", $_ = wg.equals;
function A_(e, t) {
  const r = Lp(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = Nr.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = Wt.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = Pr.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = Kt.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
    if ("requestVariables" in e) {
      const n = Mr.safeParse(e.requestVariables);
      n.success && (r.requestVariables = n.data);
    }
    if ("responses" in e) {
      const n = Xa.safeParse(e.responses);
      n.success && (r.responses = n.data);
    }
  }
  return r;
}
function S_(e) {
  return {
    v: es,
    ...e
  };
}
function xg() {
  return {
    v: es,
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "inherit",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    },
    requestVariables: [],
    responses: {}
  };
}
function E_(e) {
  return Qt.isLatest(e);
}
function kg(e) {
  const t = Qt.safeParse(e);
  return t.type === "ok" ? t.value : xg();
}
const Dr = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0)
}), jg = a.object({
  v: a.literal(1),
  name: a.string(),
  url: a.string(),
  headers: a.array(Dr).catch([]),
  query: a.string(),
  variables: a.string()
}), Rg = w({
  initial: !0,
  schema: jg
}), Me = a.object({
  authType: a.literal("none")
}), Ne = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), Pe = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), Og = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), Lr = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), Ve = a.object({
  authType: a.literal("inherit")
}), Cg = a.discriminatedUnion("authType", [
  Me,
  Ne,
  Pe,
  Og,
  Lr,
  Ve
]).and(
  a.object({
    authActive: a.boolean()
  })
), ts = a.object({
  id: a.optional(a.string()),
  v: a.literal(2),
  name: a.string(),
  url: a.string(),
  headers: a.array(Dr).catch([]),
  query: a.string(),
  variables: a.string(),
  auth: Cg
}), Ig = w({
  initial: !1,
  schema: ts,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), Mg = a.discriminatedUnion("authType", [
  Me,
  Ve,
  Ne,
  Pe,
  Lr,
  zt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), rs = ts.extend({
  v: a.literal(3),
  auth: Mg
}), Ng = w({
  initial: !1,
  schema: rs,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: 3,
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: 3,
      auth: {
        ...e.auth
      }
    };
  }
}), Jt = Lr.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Pg = a.discriminatedUnion("authType", [
  Me,
  Ve,
  Ne,
  Pe,
  Jt,
  Ft
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), ns = rs.extend({
  v: a.literal(4),
  auth: Pg
}), Vg = w({
  initial: !1,
  schema: ns,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: 4,
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      v: 4,
      auth: {
        ...e.auth
      }
    };
  }
}), as = a.discriminatedUnion("authType", [
  Me,
  Ve,
  Ne,
  Pe,
  Jt,
  Ft
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), ss = ns.extend({
  v: a.literal(5),
  auth: as
}), Dg = w({
  initial: !1,
  schema: ss,
  up(e) {
    return {
      ...e,
      v: 5
    };
  }
}), is = Vr, os = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0),
  description: a.string().catch("")
}), cs = a.discriminatedUnion("authType", [
  Me,
  Ve,
  Ne,
  Pe,
  Ft,
  Jt,
  is
]).and(
  a.object({
    authActive: a.boolean()
  })
), us = ss.extend({
  v: a.literal(6),
  auth: cs,
  headers: a.array(os).catch([])
}), Lg = w({
  schema: us,
  initial: !1,
  up(e) {
    const t = e.headers.map((r) => ({
      ...r,
      description: ""
    }));
    return {
      ...e,
      v: 6,
      headers: t
    };
  }
}), ls = a.discriminatedUnion("authType", [
  Me,
  Ve,
  Ne,
  Pe,
  Qa,
  Jt,
  is
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ug = us.extend({
  v: a.literal(7),
  auth: ls
}), Zg = w({
  schema: Ug,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: 7
      // no need to update anything for HoppGQLAuth, because we loosened the previous schema by making `clientSecret` optional
    };
  }
}), ds = 7, Hg = a.object({
  v: a.number()
}), Ur = at({
  latestVersion: 7,
  versionMap: {
    1: Rg,
    2: Ig,
    3: Ng,
    4: Vg,
    5: Dg,
    6: Lg,
    7: Zg
  },
  getVersion(e) {
    const t = Hg.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), qg = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function Bg() {
  return {
    v: ds,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: qg,
    auth: {
      authType: "inherit",
      authActive: !0
    }
  };
}
function Gg(e) {
  const t = Ur.safeParse(e);
  return t.type === "ok" ? t.value : Bg();
}
function w_(e) {
  return {
    v: ds,
    ...e
  };
}
const zg = a.object({
  v: a.literal(1),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        kt(Qt),
        kt(Ur)
      ])
    )
  )
}), fs = zg.extend({
  folders: a.lazy(() => a.array(fs))
}), Zn = w({
  initial: !0,
  schema: fs
}), hs = a.object({
  v: a.literal(2),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        kt(Qt),
        kt(Ur)
      ])
    )
  ),
  auth: a.union([za, as]),
  headers: a.union([Da, a.array(Dr)])
}), ps = hs.extend({
  folders: a.lazy(() => a.array(ps))
}), Fg = w({
  initial: !1,
  schema: ps,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), vs = hs.extend({
  v: a.literal(3),
  // AWS Signature Authorization type addition
  auth: a.union([Ja, cs]),
  // `description` field addition under `headers`
  headers: a.union([Kt, a.array(os)])
}), ms = vs.extend({
  folders: a.lazy(() => a.array(ms))
}), Kg = w({
  initial: !1,
  schema: ms,
  up(e) {
    const t = e.headers.map(
      (n) => ({
        ...n,
        description: ""
      })
    );
    return {
      ...e,
      v: 3,
      headers: t
    };
  }
}), Wg = vs.extend({
  v: a.literal(4),
  auth: a.union([Wt, ls])
}), gs = Wg.extend({
  folders: a.lazy(() => a.array(gs))
}), Qg = w({
  initial: !1,
  schema: gs,
  // @ts-expect-error
  up(e) {
    return {
      ...e,
      v: 4
    };
  }
}), Jg = a.object({
  v: a.number()
}), x_ = at({
  latestVersion: 4,
  versionMap: {
    1: Zn,
    2: Fg,
    3: Kg,
    4: Qg
  },
  getVersion(e) {
    const t = Jg.safeParse(e);
    return t.success ? t.data.v : Zn.schema.safeParse(e).success ? 1 : null;
  }
}), Yg = 4;
function ys(e) {
  return {
    v: Yg,
    ...e
  };
}
function Xg(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Xg), n = (e.requests ?? []).map(kg), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = ys({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), o;
}
function ey(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(ey), n = (e.requests ?? []).map(Gg), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = ys({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), o;
}
var ne = function(e) {
  return function(t) {
    return !e(t);
  };
}, ty = ci, ry = ii;
function _s(e, t) {
  return t === void 0 ? function(r) {
    return _s(e, r);
  } : ry(e, t) ? Qn : Jn(t[e]);
}
var bs = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, ny = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var s = e(n, t[n]);
      Wn(s) && r.push(s.value);
    }
    return r;
  };
}, ay = function(e) {
  return ny(function(t, r) {
    return e(r);
  });
}, Ts = function(e) {
  return e.slice();
}, sy = si, Hn = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, iy = function(e) {
  return function(t) {
    return Hn(Hn([], t, !0), [e], !1);
  };
}, oy = iy, cy = function(e) {
  return [e];
}, uy = oy, ly = _s, dy = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, fy = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, hy = function() {
  return {
    concat: fy().concat,
    empty: []
  };
}, Yt = Qn, it = Jn;
function $s(e) {
  return function(t) {
    return e(t) ? it(t) : Yt;
  };
}
var gr = function(e) {
  return function(t) {
    return As(t) ? Yt : it(e(t.value));
  };
}, py = Wn, As = function(e) {
  return e._tag === "None";
}, vy = function(e, t) {
  return function(r) {
    return As(r) ? e() : t(r.value);
  };
}, my = vy, Zr = my, gy = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, ae = Qs, G = Js, yy = /* @__PURE__ */ Gs(2, function(e, t) {
  return be(e) ? e : t(e.right);
}), ot = function(e) {
  return function(t) {
    return be(t) ? t : G(e(t.right));
  };
}, Xt = function(e) {
  return function(t) {
    return be(t) ? ae(e(t.left)) : t;
  };
}, be = Ks, _y = Ws, by = function(e) {
  return function(t) {
    return be(t) ? e(t.left) : t.right;
  };
}, Hr = by, qn = yy, Rt = function() {
  return Rt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t) Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, Rt.apply(this, arguments);
}, ct = function(e, t, r) {
  return G({
    value: e,
    next: t,
    start: r
  });
}, ut = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), ae({
    input: e,
    expected: t,
    fatal: r
  });
}, Ty = function(e, t) {
  return Rt(Rt({}, e), { expected: t });
}, $y = function(e, t) {
  return Ay().concat(e, t);
}, Ay = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? ni().concat(e, t) : e.input.cursor > t.input.cursor ? rr().concat(e, t) : ri({
        input: rr(),
        fatal: rr(),
        expected: hy()
      }).concat(e, t);
    }
  };
}, Sy = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, Ey = function(e) {
  return ly(e.cursor, e.buffer);
}, wy = function(e) {
  return e.cursor >= e.buffer.length;
}, xy = function(e) {
  return y(Ey(e), gr(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, re = function() {
  return re = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t) Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, re.apply(this, arguments);
}, qr = function(e) {
  return function(t) {
    return ct(e, t, t);
  };
}, ky = function() {
  return function(e) {
    return ut(e);
  };
}, jy = function(e) {
  return function() {
    return ut(e);
  };
}, R = function(e) {
  return y(Cy(Ry()), de(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? Te(r) : jy(n);
  }));
}, j = function(e, t) {
  return function(r) {
    return y(e(r), Xt(function(n) {
      return Ty(n, [t]);
    }));
  };
}, Ry = function() {
  return function(e) {
    return y(xy(e), Zr(function() {
      return ut(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return ct(r, n, e);
    }));
  };
}, Oy = function(e, t) {
  return function(r) {
    return y(e(r), qn(function(n) {
      return y(t(n.value)(n.next), qn(function(s) {
        return ct(s.value, s.next, r);
      }));
    }));
  };
}, er = function(e, t) {
  return function(r) {
    var n = e(r);
    return _y(n) || n.left.fatal ? n : y(t()(r), Xt(function(s) {
      return $y(n.left, s);
    }));
  };
}, Cy = function(e) {
  return function(t) {
    return y(e(t), ot(function(r) {
      return re(re({}, r), { value: [r.value, t] });
    }));
  };
}, Ss = function(e) {
  return fe(function() {
    return Te(e.empty);
  });
}, Br = function() {
  return j(function(e) {
    return wy(e) ? ct(void 0, e, e) : ut(e);
  }, "end of file");
}, Iy = function(e) {
  return y(Gr(e), fe(function() {
    return Te([]);
  }));
}, Gr = function(e) {
  return y(e, de(function(t) {
    return Fr(cy(t), function(r) {
      return y(e, k(function(n) {
        return ae(uy(n)(r));
      }), fe(function() {
        return Te(G(r));
      }));
    });
  }));
}, My = function(e, t) {
  return function(r) {
    return y(e, de(function() {
      return r;
    }), Kr(function() {
      return t;
    }));
  };
}, Es = function(e) {
  return My(e, e);
}, Ny = function(e) {
  return Iy(R(ne(e)));
}, zr = function(e, t) {
  return y(t, k(function() {
    return sy;
  }), fe(function() {
    return Py(e, t);
  }));
}, Py = function(e, t) {
  return y(e, de(function(r) {
    return Fr(ui(r), function(n) {
      return y(t, k(function() {
        return G(n);
      }), fe(function() {
        return y(e, k(function(s) {
          return ae(ty(s)(n));
        }));
      }));
    });
  }));
}, lt = function(e, t) {
  return function(r) {
    return y(e(r), ot(function(n) {
      return re(re({}, n), { value: t(n.value) });
    }));
  };
}, ws = function(e, t) {
  return tr(e, function(r) {
    return lt(t, r);
  });
}, tr = function(e, t) {
  return Oy(e, t);
}, Fr = function(e, t) {
  var r = function(n) {
    return function(s) {
      return be(s.value) ? ae({ value: s.value.left, stream: s.next }) : G(ct(s.value.right, s.next, n));
    };
  };
  return function(n) {
    return gy({ value: e, stream: n }, function(s) {
      var i = t(s.value)(s.stream);
      return be(i) ? G(ut(s.stream, i.left.expected, i.left.fatal)) : r(n)(i.right);
    });
  };
}, Vy = function(e, t) {
  return er(e, t);
}, k = function(e) {
  return function(t) {
    return lt(t, e);
  };
}, Te = qr, de = function(e) {
  return function(t) {
    return tr(t, e);
  };
}, Kr = function(e) {
  return function(t) {
    return tr(t, function(r) {
      return lt(e(r), function() {
        return r;
      });
    });
  };
}, fe = function(e) {
  return function(t) {
    return Vy(t, e);
  };
}, Dy = "Parser", Ly = function(e) {
  return {
    concat: function(t, r) {
      return ws(lt(t, function(n) {
        return function(s) {
          return e.concat(n, s);
        };
      }), r);
    }
  };
}, Uy = function(e) {
  return re(re({}, Ly(e)), { empty: qr(e.empty) });
}, Zy = {
  URI: Dy,
  map: lt,
  ap: ws,
  chain: tr,
  chainRec: Fr
}, xs = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, ks = function(e) {
  return function(t) {
    return y(t, k(function(r) {
      return xs({}, e, r);
    }));
  };
}, yr = function(e, t) {
  return function(r) {
    return y(r, de(function(n) {
      return y(t(n), k(function(s) {
        return xs(n, e, s);
      }));
    }));
  };
}, Hy = function(e) {
  return ai(e)(e.empty);
}, qy = Ss(Tr), $e = function(e) {
  return j(R(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, By = function(e) {
  return j(R(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, Wr = function(e) {
  return qy(dt(e));
}, dt = function(e) {
  return y(Gr(e), k(function(t) {
    return t.join("");
  }));
}, Qr = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, _r = j(R(Qr), "a digit"), Gy = /^\s$/, js = function(e) {
  return Gy.test(e);
}, Rs = j(R(js), "a whitespace"), zy = function(e) {
  return e === "_";
}, ft = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, Os = function(e) {
  return ft(e) || Qr(e) || zy(e);
};
j(R(Os), "a word character");
j(R(ft), "a letter");
var Fy = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
j(R(Fy), "an unicode letter");
var Cs = function(e) {
  return ft(e) && e === e.toUpperCase();
};
j(R(Cs), "an upper case letter");
var Is = function(e) {
  return ft(e) && e === e.toLowerCase();
};
j(R(Is), "a lower case letter");
j(R(ne(Qr)), "a non-digit");
var Ms = j(R(ne(js)), "a non-whitespace character");
j(R(ne(Os)), "a non-word character");
j(R(ne(ft)), "a non-letter character");
j(R(ne(Cs)), "anything but an upper case letter");
j(R(ne(Is)), "anything but a lower case letter");
var Ns = function(e) {
  return j(Zy.chainRec(e, function(t) {
    return y(Qy(0, t), Zr(function() {
      return Te(G(e));
    }, function(r) {
      return y($e(r), de(function() {
        return Te(ae(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, br = Hy(Uy(Tr)), tt = Ss(Tr), Ky = function(e) {
  return tt(Wy(e));
}, Wy = function(e) {
  return y(Gr(e), k(function(t) {
    return t.join("");
  }));
}, Qy = function(e, t) {
  return e < t.length ? it(t.charAt(e)) : Yt;
}, Jy = Wr(Rs);
dt(Rs);
Wr(Ms);
dt(Ms);
var Yy = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? Yt : it(t);
};
j(y(br([tt($e("-")), dt(_r)]), k(function(e) {
  return +e;
})), "an integer");
j(y(br([tt($e("-")), Wr(_r), tt(br([$e("."), dt(_r)]))]), de(function(e) {
  return y(Yy(e), Zr(function() {
    return ky();
  }, qr));
})), "a float");
var Xy = Es($e('"'))(Ky(er(Ns('\\"'), function() {
  return By('"');
})));
function Ps(e) {
  return function(t) {
    return t(Sy(e.split("")));
  };
}
const Bn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), e_ = ["#", ":"], Ae = Es(Jy), Jr = (e) => (t) => t.join(e), Yr = (e) => y(
  Ny((t) => e.includes(t)),
  k(Jr(""))
), t_ = J(
  Yr,
  Kr(() => R(() => !0))
), Vs = y(
  Xy,
  k((e) => JSON.parse(`"${e}"`))
), r_ = y(
  Ae(Vs),
  fe(
    () => y(
      Yr([":", `
`]),
      k(Yn)
    )
  )
), n_ = y(
  Ae(Vs),
  fe(
    () => y(
      Yr([`
`]),
      k(Yn)
    )
  )
), Ds = y(
  tt(Ns("#")),
  k(ne(Xn))
), Ls = y(
  Ae(Ds),
  ks("commented"),
  yr("key", () => Ae(r_)),
  Kr(() => $e(":")),
  yr("value", () => n_)
), a_ = y(
  Ae(Ds),
  ks("commented"),
  yr("key", () => er(
    t_([`
`]),
    () => y(
      zr(R((e) => !0), Br()),
      k(J(
        Ts,
        Jr("")
      ))
    )
  )),
  k(J(
    $s(({ key: e }) => !Xn(e))
  ))
), s_ = y(
  zr(Ae(Ls), Br())
), i_ = y(
  zr(
    er(
      y(Ls, k(it)),
      () => y(
        a_,
        k(J(
          gr((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    Br()
  ),
  k(J(
    ay(J(
      $s(py),
      gr((e) => e.value)
    ))
  ))
), o_ = (e) => {
  if (e_.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, Gn = (e) => o_(e) ? JSON.stringify(e) : e, k_ = (e) => y(
  e,
  dy(
    J(
      Bn("key", Gn),
      Bn("value", Gn),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  Jr(`
`)
), c_ = (e) => y(
  i_,
  Ps(e),
  Xt((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  ot(
    ({ value: t }) => y(
      t,
      bs(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), j_ = (e) => y(
  s_,
  Ps(e),
  Xt((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  ot(
    ({ value: t }) => y(
      t,
      bs(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), R_ = J(
  c_,
  ot(Ts),
  Hr(() => [])
), u_ = a.object({
  id: a.optional(a.string()),
  name: a.string(),
  variables: a.array(
    a.object({
      key: a.string(),
      value: a.string()
    })
  )
}), zn = w({
  initial: !0,
  schema: u_
}), Us = () => Math.random().toString(36).substring(2, 16), l_ = a.object({
  v: a.literal(1),
  id: a.string(),
  name: a.string(),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1).catch(!1)
      })
    ])
  )
}), d_ = w({
  initial: !1,
  schema: l_,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id || Us(),
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), Zs = [
  // Common
  {
    key: "$guid",
    description: "A v4 style GUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : r === 14 ? t += "4" : r === 19 ? t += e.charAt(8 + Math.floor(Math.random() * 4)) : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$timestamp",
    description: "The current UNIX timestamp in seconds.",
    getValue: () => Math.floor(Date.now() / 1e3).toString()
  },
  {
    key: "$isoTimestamp",
    description: "The current ISO timestamp at zero UTC.",
    getValue: () => (/* @__PURE__ */ new Date()).toISOString()
  },
  {
    key: "$randomUUID",
    description: "A random 36-character UUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  // Text, numbers, and colors
  {
    key: "$randomAlphaNumeric",
    description: "A random alpha-numeric character.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      return e.charAt(Math.floor(Math.random() * e.length));
    }
  },
  {
    key: "$randomBoolean",
    description: "A random boolean value.",
    getValue: () => Math.random() < 0.5 ? "true" : "false"
  },
  {
    key: "$randomInt",
    description: "A random integer between 0 and 1000.",
    getValue: () => Math.floor(Math.random() * 1e3).toString()
  },
  {
    key: "$randomColor",
    description: "A random color.",
    getValue: () => {
      const e = ["red", "green", "blue", "yellow", "purple", "orange"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomHexColor",
    description: "A random hex value.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "#";
      for (let r = 0; r < 6; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomAbbreviation",
    description: "A random abbreviation.",
    getValue: () => {
      const e = [
        "SQL",
        "PCI",
        "JSON",
        "HTML",
        "CSS",
        "JS",
        "TS",
        "API"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Internet and IP addresses
  {
    key: "$randomIP",
    description: "A random IPv4 address.",
    getValue: () => Array.from(
      { length: 4 },
      () => Math.floor(Math.random() * 256)
    ).join(".")
  },
  {
    key: "$randomIPV6",
    description: "A random IPv6 address.",
    getValue: () => Array.from(
      { length: 8 },
      () => Math.floor(Math.random() * 65536).toString(16)
    ).join(":")
  },
  {
    key: "$randomMACAddress",
    description: "A random MAC address.",
    getValue: () => Array.from(
      { length: 6 },
      () => Math.floor(Math.random() * 256).toString(16)
    ).join(":")
  },
  {
    key: "$randomPassword",
    description: "A random 15-character alpha-numeric password.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let t = "";
      for (let r = 0; r < 15; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomLocale",
    description: "A random two-letter language code (ISO 639-1).",
    getValue: () => {
      const e = ["ny", "sr", "si"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomUserAgent",
    description: "A random user agent.",
    getValue: () => {
      const e = [
        "Mozilla/5.0 (Macintosh; U; Intel Mac OS X 10.9.8; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomProtocol",
    description: "A random internet protocol.",
    getValue: () => {
      const e = ["http", "https"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomSemver",
    description: "A random semantic version number.",
    getValue: () => Array.from(
      { length: 3 },
      () => Math.floor(Math.random() * 10)
    ).join(".")
  },
  // Names
  {
    key: "$randomFirstName",
    description: "A random first name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomLastName",
    description: "A random last name.",
    getValue: () => {
      const e = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomFullName",
    description: "A random first and last name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ], t = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return `${e[Math.floor(Math.random() * e.length)]} ${t[Math.floor(Math.random() * t.length)]}`;
    }
  },
  {
    key: "$randomNamePrefix",
    description: "A random name prefix.",
    getValue: () => {
      const e = ["Dr.", "Ms.", "Mr.", "Mrs.", "Miss", "Prof."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomNameSuffix",
    description: "A random name suffix.",
    getValue: () => {
      const e = ["I", "MD", "DDS", "PhD", "Esq.", "Jr."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Addresses
  {
    key: "$randomCity",
    description: "A random city name.",
    getValue: () => {
      const e = [
        "New York",
        "Los Angeles",
        "Chicago",
        "Houston",
        "Phoenix",
        "Philadelphia"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // profession
  {
    key: "$randomJobArea",
    description: "A random job area.",
    getValue: () => {
      const e = [
        "Mobility",
        "Intranet",
        "Configuration",
        "Development",
        "Design",
        "Testing"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobDescriptor",
    description: "A random job descriptor.",
    getValue: () => {
      const e = [
        "Forward",
        "Corporate",
        "Senior",
        "Junior",
        "Lead",
        "Principal"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobTitle",
    description: "A random job title.",
    getValue: () => {
      const e = [
        "International Creative Liaison",
        "Global Branding Officer",
        "Dynamic Data Specialist",
        "Internal Communications Consultant",
        "Productivity Analyst",
        "Regional Applications Developer"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobType",
    description: "A random job type.",
    getValue: () => {
      const e = ["Supervisor", "Manager", "Coordinator", "Director"];
      return e[Math.floor(Math.random() * e.length)];
    }
  }
  // TODO: Support various other predefined variables
], f_ = a.object({
  v: a.number()
}), O_ = at({
  latestVersion: 1,
  versionMap: {
    0: zn,
    1: d_
  },
  getVersion(e) {
    const t = f_.safeParse(e);
    return t.success ? t.data.v : zn.schema.safeParse(e).success ? 0 : null;
  }
}), Ot = /<<([^>]*)>>/g, Ct = 10, Hs = "ENV_EXPAND_LOOP", Fn = 1;
function h_(e, t) {
  let r = e, n = 0;
  for (; r.match(Ot) != null && n <= Ct; )
    r = r.replace(Ot, (s) => {
      const i = s.replace(/[<>]/g, ""), o = Zs.find(
        (u) => u.key === i
      );
      if (o)
        return o.getValue();
      const c = t.find((u) => u.key === i);
      return c && "value" in c ? c.value : s;
    }), n++;
  return n > Ct ? ae(Hs) : G(r);
}
const C_ = (e, t) => y(
  h_(e, t),
  Hr(() => e)
);
function p_(e, t, r = !1, n = !1) {
  if (!t || !e)
    return G(e);
  let s = e, i = 0, o = !1;
  for (; s.match(Ot) != null && i <= Ct && !o; )
    s = decodeURI(encodeURI(s)).replace(Ot, (c, u) => {
      const l = Zs.find(
        (_) => _.key === u
      );
      if (l)
        return l.getValue();
      const f = t.find((_) => _ && _.key === u);
      return f && "value" in f ? f.secret && n ? (o = !0, `<<${u}>>`) : f.secret && r ? "*".repeat(
        f.value.length
      ) : f.value : "";
    }), i++;
  return i > Ct ? ae(Hs) : G(s);
}
const I_ = (e, t, r = !1, n = !1) => y(
  p_(e, t, r, n),
  Hr(() => e)
), v_ = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), M_ = (e) => {
  if (e.v && e.v === Fn) return e;
  const t = e.id || Us(), r = e.name ?? "Untitled", n = (e.variables ?? []).map(v_);
  return {
    v: Fn,
    id: t,
    name: r,
    variables: n
  };
}, m_ = a.array(
  a.union([
    a.object({
      key: a.string(),
      value: a.string(),
      secret: a.literal(!1)
    }),
    a.object({
      key: a.string(),
      secret: a.literal(!0)
    }),
    a.object({
      key: a.string(),
      value: a.string()
    })
  ])
), Kn = w({
  initial: !0,
  schema: m_
}), g_ = a.object({
  v: a.literal(1),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1)
      })
    ])
  )
}), y_ = w({
  initial: !1,
  schema: g_,
  up(e) {
    return {
      v: 1,
      variables: e.map((r) => "value" in r ? {
        key: r.key,
        value: r.value,
        secret: !1
      } : {
        key: r.key,
        secret: !0
      })
    };
  }
}), __ = a.object({
  v: a.number()
}), N_ = at({
  latestVersion: 1,
  versionMap: {
    0: Kn,
    1: y_
  },
  getVersion(e) {
    const t = __.safeParse(e);
    return t.success ? t.data.v : Kn.schema.safeParse(e).success ? 0 : null;
  }
});
export {
  Ga as AuthCodeGrantTypeParams,
  pg as ClientCredentialsGrantTypeParams,
  Yg as CollectionSchemaVersion,
  O_ as Environment,
  Fn as EnvironmentSchemaVersion,
  Ir as FormDataKeyValue,
  os as GQLHeader,
  ds as GQL_REQ_SCHEMA_VERSION,
  N_ as GlobalEnvironment,
  Zs as HOPP_SUPPORTED_PREDEFINED_VARIABLES,
  x_ as HoppCollection,
  ls as HoppGQLAuth,
  Jt as HoppGQLAuthAPIKey,
  is as HoppGQLAuthAWSSignature,
  Ne as HoppGQLAuthBasic,
  Pe as HoppGQLAuthBearer,
  Ve as HoppGQLAuthInherit,
  Me as HoppGQLAuthNone,
  Qa as HoppGQLAuthOAuth2,
  Ur as HoppGQLRequest,
  Wt as HoppRESTAuth,
  qa as HoppRESTAuthAPIKey,
  Vr as HoppRESTAuthAWSSignature,
  Oe as HoppRESTAuthBasic,
  Ce as HoppRESTAuthBearer,
  gg as HoppRESTAuthDigest,
  Ie as HoppRESTAuthInherit,
  Re as HoppRESTAuthNone,
  mg as HoppRESTAuthOAuth2,
  Kt as HoppRESTHeaders,
  Pr as HoppRESTParams,
  Nr as HoppRESTReqBody,
  b_ as HoppRESTReqBodyFormData,
  Qt as HoppRESTRequest,
  Tg as HoppRESTRequestResponse,
  Xa as HoppRESTRequestResponses,
  Mr as HoppRESTRequestVariables,
  bg as HoppRESTResponseOriginalRequest,
  Gt as ImplicitOauthFlowParams,
  vg as PasswordGrantTypeParams,
  es as RESTReqSchemaVersion,
  T_ as ValidContentTypesList,
  Bg as getDefaultGQLRequest,
  xg as getDefaultRESTRequest,
  $_ as isEqualHoppRESTRequest,
  E_ as isHoppRESTRequest,
  Sg as knownContentTypes,
  ys as makeCollection,
  w_ as makeGQLRequest,
  S_ as makeRESTRequest,
  C_ as parseBodyEnvVariables,
  h_ as parseBodyEnvVariablesE,
  R_ as parseRawKeyValueEntries,
  c_ as parseRawKeyValueEntriesE,
  I_ as parseTemplateString,
  p_ as parseTemplateStringE,
  k_ as rawKeyValueEntriesToString,
  A_ as safelyExtractRESTRequest,
  j_ as strictParseRawKeyValueEntriesE,
  Gg as translateToGQLRequest,
  M_ as translateToNewEnvironment,
  v_ as translateToNewEnvironmentVariables,
  ey as translateToNewGQLCollection,
  Xg as translateToNewRESTCollection,
  kg as translateToNewRequest
};
